import React, { useEffect, useState, Fragment, useReducer } from 'react';
import moment from 'moment';
import { Link, useHistory  } from 'react-router-dom';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import ReactSelect from 'react-select';
import NumericInput from 'react-numeric-input';
import InputMask from 'react-input-mask';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DatePicker, Select, notification } from 'antd';
import { AES } from 'crypto-js';
import { bookingCamperDetailService, getAdditionalExperienceService, productListByBookingSerivce, updateRemainingAmountService, medicalDocumentStatusChangeSerivce, updateBookingDateService, editAllAnsByDirService } from '../../services/directorServices';
import { InformationIcon, PDFIcon, ProgramLoader } from '../reuse/SVG';
import { getHtml, getSignature, _calculateAge, resHandle, removeTime, calculateProductRefundPrice, MAX_INSURANCE_PRODUCTS, getCardTypeImage, initialChargeAmountModalState, ChargeAmountReducer, getPriceCategory, calculateInsuranceCamperDisplay, convertTimeToCurrentTimeZone, PaymentDateFormat } from '../../helper';
import { saveCabinIdAction } from '../../store/ui';
import { camperCancelRefundAction, cancelRefundAction, deleteCardService, getCartProductsService, getCustomerCardsService2, getProgramDetails, markPrimaryCard, refundBookingByCampers, removeFromCart, updateBookingKeyService, updateCartProductsService } from '../../services/programService';
import { CustomNumber } from '../reuse/FormControls';
import { resendBookingEmailService, uploadMedia } from '../../services/campersServices';
import { getCampDetailService } from '../../services/userServices';
import { addProductIntoCartService, deductPaylaterAmountService, getProductsService } from '../../services/onlineStoreService';
import settings from '../../config/settings';
import ProductPaymentGateway from '../payment/ProductPaymentGateway';
import ShowProductInfo from './ShowProductInfo';
import Addon from '../online-store/Addon';
import { v4 as uuidv4 } from 'uuid';
import QuestionsComponent from '../common/Questions/QuestionsComponent';
import AddInsurance from './AddInsurance';
import { addZero, camperCancellationOptions, cancellationOptions, getColors2, getSizes, getTaxForPrice, getWithTaxPrice, isOnlySpaces, sliderSetting, trimSpace } from './bookingHelper';
import ChargeCamperModal from '../reuse/ChargeCamper/ChargeCamperModal';
import GenericTooltip from '../reuse/Tooltip/GenericToolTip';
import { WarningOutlined, } from '@ant-design/icons'; 
import PdfViewer from '../common/PdfViewer/PdfViewer';
import AddNewCard from '../payment/AddNewCard';
import SecureInput from '../payment/SecureInput';
import CreditCard from '../payment/CreditCard';

const { Option } = Select;

const CamperDetail = props => {
    let history = useHistory();
    let { bookingId, programId, camperId } = props.match.params;
    const [loader, setLoader] = useState(true);
    const [refundableLoader, setRefundableLoader] = useState(false);
    const [refundModal, setRefundModal] = useState(false);
    const [allData, setAllData] = useState('');
    const [payments, setPayments] = useState([])
    const [additionalExperience, setAdditionalExperience] = useState([]);
    const [confimCancelBookingModal, setConfimCancelBookingModal] = useState(false);
    const [newRefundAmount, setNewRefundAmount] = useState(0);
    const [newRefundAmountErr, setNewRefundAmountErr] = useState('');
    const [amountUpdateModal, setAmountUpdateModal] = useState(false);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [remainingAmountErr, setRemainingAmountErr] = useState('');
    const [remainingAmountLoader, setRemainingAmountLoader] = useState(false);
    const [emiId, setEmiId] = useState('');
    const [activeQuestions, setActiveQues] = useState([]);
    const [answerModal, setAnswerModal] = useState(false);
    const [paymentInfoModal, setPaymentInfoModal] = useState(false);

    const [paymentModal, setPaymentModal] = useState(false);
    const [activePaymentObject, setActivePaymentObject] = useState({});
    const [activePaymentObject2, setActivePaymentObject2] = useState({});

    const [amount, setAmount] = useState(0);
    const [emiObj, setEmiObj] = useState('');
    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');


    const [creditCardRate, setCreditCardRate] = useState({});
    const [achTransferRate, setAchTransferRate] = useState({});
    const [byCheque, setByCheque] = useState({});
    const [splitPaymentRate, setSplitPaymentRate] = useState({});

    const [paymentMethod, setPaymentMethod] = useState(0);
    const [paymentMethodErr, setPaymentMethodErr] = useState('');
    const [isNewCard, setIsNewCard] = useState(false);
    const [cardList, setCardList] = useState([]);


    const [btnLoader, setBtnLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [activeForm, setActiveForm]       = useState({});



    const [customerProfileId, setCustomerProfileId] = useState([]);
    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdErr, setCustomerPaymentProfileIdErr] = useState('');
    const [customerPaymentProfileIdError, setCustomerPaymentProfileIdError] = useState('');

    const [resErr, setResErr] = useState('');

    const [bankAccountType, setBankAccountType] = useState('');
    const [bankAccountNum, setBankAccountNum] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');
    const [bankAccountTypeErr, setBankAccountTypeErr] = useState('');
    const [bankAccountNumErr, setBankAccountNumErr] = useState('');
    const [routingNumberErr, setRoutingNumberErr] = useState('');
    const [nameOnAccountErr, setNameOnAccountErr] = useState('');

    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');

    const [campRelatedProductsModal, setCampRelatedProductsModal] = useState(false);
    const [campRelatedProducts, setCampRelatedProducts] = useState([]);
    const [campRelatedProductCount, setCampRelatedProductCount] = useState(0);
    const [productCheckoutModal, setProductCheckoutModal] = useState(false);
    const [passData, setPassData] = useState({});
    const [passDataArr, setPassDataArr] = useState([]);
    const [userProductList, setUserProductList] = useState([]);
    const [refundProductModal, setRefundProductModal] = useState(false);
    const [confirmCancelProductModal, setConfirmCancelProductModal] = useState(false);
    const [activeProductData, setActiveProductData] = useState('');
    const [productRefundAmount, setProductRefundAmount] = useState(0);
    const [productRefundAmountFixed, setProductRefundAmountFixed] = useState(0);
    const [productRefundAmountErr, setProductRefundAmountErr] = useState('');
    const [note, setNote] = useState('');
    const [noteErr, setNoteErr] = useState('');
    const [medicalDocumentStatus, setMedicalDocumentStatus] = useState(0);
    const [primaryCardId,setPrimaryCardId] = useState('');
    const [isDeletePopup,setIsDeletePopup] = useState(false);
    const [deleteCardToken,setDeleteCardToken] = useState('');
    const [addPrimary, setAddPrimary] = useState(false);
    const [isSwitchPrimaryCard,setIsSwitchPrimaryCard] = useState(false);
    const [holdPrimaryId, setHoldPrimaryId] = useState('');
    const [updateEMIDateModal, setUpdateEMIDateModal] = useState(false);
    const [activeEMI, setActiveEMI] = useState('');
    const [selectEmiDate, setSelectEmiDate] = useState('');
    const [addons, setAddons] = useState([]);
    const [isViewProducts, setIsViewProducts] = useState(false)
    const [activeProduct, setActiveProduct] = useState('')
    const [showInsurance, setShowInsurance] = useState([])

    const [isViewAddProducts, setIsViewAddProducts] = useState(false)
    const [activeAddProduct, setActiveAddProduct] = useState('')
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedColorErr, setSelectedColorErr] = useState('');
    const [selectedSizeErr, setSelectedSizeErr] = useState('');
    const [currentCount, setCurrentCount] = useState(1);
    const [existingWarning, setExistingWarning] = useState('')
    const [activeProductIndex, setActiveProductIndex] = useState(-1);
    const [activeCartId, setActiveCartId] = useState('');
    const [loading, setLoading] = useState(false);
    const [productCancellationType, setProductCancellationType] = useState(cancellationOptions[0]);
    const [cancellationType, setCancellationType] = useState(camperCancellationOptions[0]);
    const [paymentList, setPaymentList] = useState([]);
    const [selectedRefundTransaction, setSelectedRefundTransaction ] = useState({});
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [programRefundAmount, setProgramRefundAmount] = useState(0);
    const [CancelRefundActionError, setCancelRefundActionError] = useState('')
    const [insuranceModal, setInsuranceModal] = useState(false);
    const [programDetails, setProgramDetails] = useState({})
    const [programPriceText, setProgramPriceText] = useState({label:"Program Price", value: 0})
    const [addedinsurance, setAddedInsurance] = useState([])
    const [modalState, dispatch] = useReducer(ChargeAmountReducer, initialChargeAmountModalState);
    const [chargeModal, setChargeModal] = useState(false)

    const handleChargeAmountReason = (newReason) => {
        if (isOnlySpaces(newReason)) {
            handleReasonError('Please Enter a valid reason.')
        } else {
            const _reason = trimSpace(newReason)
            dispatch({ type: 'SET_REASON', payload: _reason });
            handleReasonError('')
        }
    };
    const handleReasonError = (error) => {
        dispatch({ type: 'SET_REASON_ERROR', payload: error });
    };
    const handleChargeAmountChange = (amount) => {
        dispatch({ type: 'SET_AMOUNT', payload: amount });
    }
    const handleCamperChange = (camper) => {
        dispatch({ type: 'SET_CAMPER_CHANGE', payload: camper });
    }
    const handleMetaDataChange = (b_id, b_no, p_id) => {
        dispatch({ type: 'SET_META_DATA', payload: { b_id, b_no, p_id } });
    }
    const handleCloseChargeModal = (type=1) => {
        dispatch({ type: 'RESET', payload: initialChargeAmountModalState })
        setChargeModal(false)
        setCancellationType(cancellationOptions[0]);
        if(type===1){
            getCamperBookingDetail()
        }
    }
    const handleBankAccountNumberChange = (e) => {
        setBankAccountNum(e.target.value);
        setBankAccountNumErr('');
   }
    useEffect(() => {
        let query = `?programId=${programId}`
        getProgramDetails(query).then((res) => {
            if (res.data.statusCode) {
                const programData = res.data.responseData.result;
                setProgramDetails(programData)
            }
        })
    }, []);

    useEffect(() => {
        if(Object.keys(programDetails).length && allData?.bookingDate){
            const priceSelectionDates ={
                dateEarlyRegisterOpen: programDetails?.dateEarlyRegisterOpen ,
                dateEarlyRegisterClosed: programDetails?.dateEarlyRegisterClosed ,
                registerOpenDate: programDetails?.registerOpenDate ,
                registerCloseDate: programDetails?.registerCloseDate ,
                dateLateRegisterOpen: programDetails?.dateLateRegisterOpen ,
                dateLateRegisterClosed: programDetails?.dateLateRegisterClosed ,
            }
            const selectionPrice = {
                lateRegistrationFee:programDetails.lateRegistrationFee,
                earlyBirdPrice: programDetails.earlyBirdPrice,
                registrationFee: programDetails.registrationFee,
            }
            setProgramPriceText(getPriceCategory(priceSelectionDates, allData?.bookingDate, selectionPrice))
        }
    }, [programDetails, allData])


    const handleShowEditModal = item => {
        setAnswerModal(true);
        setActiveQues(item);//
    }


    const handleRefundTransaction = (e) => {
        setSelectedRefundTransaction(e);
        const _selectedTransaction = paymentList.find(tr => tr.transaction_id === e.value);
        let remainingAmount = _selectedTransaction.amount;
        if(_selectedTransaction?.refunds?.length  > 0){
            for(let i =0; i < _selectedTransaction?.refunds?.length; i++  ){
                remainingAmount -= _selectedTransaction?.refunds[i]?.amount;
            }
        }
        
        setProgramRefundAmount(remainingAmount)
        setSelectedTransaction(_selectedTransaction);
    }
    const handleSubmitAns = () => {
        let validate = true
        //Add validation before submit 
        // Task1: fetch all queestions and map them for questionId/AnswerId(_id) and answers
        let _questions = [...activeQuestions];
        if(activeQuestions.length){
            _questions = _questions.map(item => {
                const _newItem = {
                    ...item,
                    answerErr: (item?.isRequred === 1 ) ? (item.answer || item?.isSkipped) ? '' : 'This field is required' : ''  
                }
                const {isSkipped, ...newItem} = _newItem
                 return newItem; 
            })
            let has_questions_err = _questions.filter(item => item.answerErr);

            if(has_questions_err.length){
                let element = document.getElementById(has_questions_err[0]._id);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
                setActiveQues(_questions);
                validate = false
            }
        }
        if(validate){
            const _allAnswers = activeQuestions.map(item => ({
                                answerid:item?._id, 
                                questionId: item.questionId,
                                answer: Array.isArray(item.answer) ? item.answer.join(", ") : item.answer
                                }))
    
            const parmas = {
                programId, 
                camperId,
                bookingId,
                answers: [..._allAnswers]
    
            }
            editAllAnsByDirService(parmas).then((res) => {
                // setAnswerModal(false)
                if(res.data.statusCode){
                    notification.open({
                        description:
                            `${res.data.responseData.message}`,
                    });
                }else{
                    notification.open({
                        description:
                            `${res.data.responseData.message}`,
                    });
                }
               
                getCamperBookingDetail();
         }).catch((error) => console.log(error)).finally(() =>setAnswerModal(false) )
        }
    }


    const getCamperBookingDetail = () => {
        let query = `?bookingId=${bookingId}&programId=${programId}&camperId=${camperId}`;
        
        bookingCamperDetailService(query).then(res => {

            setLoader(false);
            if(res?.data?.statusCode){
                setAllData(res.data.responseData.result);
                getPayments(res.data.responseData.result);
                let _addedinsurance = []
                const _allCamperData = res.data.responseData.result.campers;
                if(_allCamperData?.insuranceData?.length > 0){
                    _addedinsurance = _allCamperData?.insuranceData?.filter(ins => ins.insuranceStatus === 1)
                    if(_addedinsurance?.length > 0){
                        _addedinsurance =  _addedinsurance.map(ins => {
                            return ins.product.name
                        })
                    }
                }
                setAddedInsurance(_addedinsurance); 
                setUserId(res.data.responseData.result.userId);
                setUserEmail(res.data.responseData.result.campers.email);
                setMedicalDocumentStatus(res.data.responseData.result?.campers.medicalDocumentStatus) 
            }
        })

        let params = {
            bookingId,
            programId,
            camperId
        }
        getAdditionalExperienceService(params).then(res => {
            if(res?.data?.statusCode){
                setAdditionalExperience(res.data.responseData.result);
            }
        })
    }



    useEffect(() => {
        getCamperBookingDetail();

        return () => {
            props.saveCabinIdAction('');
        }
    }, [])

    const getPayments = (allData) => {
        if(allData?.paymentArr){
            setPayments(allData.paymentArr.filter(py => {
                const metadata = py?.metadata;
                if (!metadata) return true;
            
                if (metadata.type === 'additionalCharge') {
                    return metadata.camperId === camperId;
                }

                return true;
            }))
        }
    }

    


    const removeStr = s => {
        if(s.includes(',')){
            return s.replace('[', '').replace(']', '').replace(/["]/g, "").replace(/[,]/g, ", ")
        }else{
            return s.replace(/["]/g, "")
        } 
    }



    const handleRefund = () => {

        if(newRefundAmount === 0){
            setNewRefundAmountErr('Refund amount not valid')
        }else if(newRefundAmount > allData.campers.refundableFee){
            setNewRefundAmountErr('Refund amount not bigger then program cost')
        }else {
            let params = {
                bookingId,
                programs: [
                    {
                        programId,
                        campers: [
                            {
                                programCost: newRefundAmount,
                                camperId
                            }
                        ]
                    }
                ]
            }
    
            setRefundableLoader(true);
    
            refundBookingByCampers(params).then(res => {
                setRefundableLoader(false);
                if(res?.data?.statusCode){
                    history.push(`/bookings/${programId}`)
                    notification.open({
                        message: 'Booking',
                        description:
                            'Booking canceled',
                    });
                }
            })
        }

        


    }


    const handleCancelRefundBookingAction = () => {    
            setBtnLoader(true)
            if(cancellationType.value ===2 && (allData?.campers?.status === 2 || allData?.campers?.status === 4) ){
                setCancelRefundActionError("Booking is already canceled, please try other options.")
                setBtnLoader(false)
                return
            }
            let params = {
                bookingId,
                programId,
                programCost: allData.campers.refundableFee,
                camperId, 
                actionType: cancellationType.value
            }

            if(cancellationType.value === 3 || cancellationType.value === 4){
               
                if(newRefundAmount === 0){
                    setNewRefundAmountErr('Refund amount must be grater than zero.')
                    setBtnLoader(false)
                    return;
                }else if(newRefundAmount > programRefundAmount){
                    setNewRefundAmountErr('Refund amount must not be greater than the transaction amount.')
                    setBtnLoader(false)
                    return
                }else{
                    params.programCost = newRefundAmount;
                    params['transaction_id'] = selectedRefundTransaction.value;
                }
            }
    
            camperCancelRefundAction(params).then(res => {
                if(res.data.statusCode){
                    notification.open({
                        message: 'Booking',
                        description:
                            'Booking canceled',
                    });
                }else{
                    notification.open({
                        description: 'Something went wrong. Please try Again.',
                    });  
                }
                handleCloseRefundCancelCamper()
                getCamperBookingDetail();            })

    }

    const handleCloseRefundCancelCamper = () => {
        setCancellationType(cancellationOptions[0]);
        setConfimCancelBookingModal(false);
        setBtnLoader(false)
        setSelectedRefundTransaction({})
        setSelectedTransaction({})
        setNewRefundAmount(0)
        setProgramRefundAmount(0)
        setNewRefundAmountErr('')
        setCancelRefundActionError('')
    }


    const handleUpdateAmount = () => {

        if(note === '' || note === undefined || note === null){
            setNoteErr('Please add a note');
        }else{

            if(remainingAmount || remainingAmount === 0){

                let params = '';
    
                
    
                if(emiId._id){
                    params = {
                        bookingId,
                        remainingAmount: (emiId.remainingAmount || 0) - (emiId.emiAmount - remainingAmount),
                        emiId: emiId._id,
                        emiAmount:  remainingAmount,
                        notes: {
                            notes: note,
                            diff: emiId.emiAmount < remainingAmount ? true : false,
                            difference: Math.abs(remainingAmount - emiId.emiAmount)
                        }
                    }
                }else{
                    params = {
                        bookingId,
                        remainingAmount: remainingAmount,
                        notes: {
                            notes: note,
                            diff: activePaymentObject2.remainingAmount < remainingAmount ? true : false,
                            difference: Math.abs(remainingAmount - activePaymentObject2.remainingAmount )
                        }
                    }
                }
    
    
    
                setRemainingAmountLoader(true);
                updateRemainingAmountService(params).then(res => {
                    setRemainingAmountLoader(false);
                    setAmountUpdateModal(false);
                    setEmiId('');
                    setNote('');
                    if(res?.data?.statusCode){
                        notification.open({
                            message: 'Amount',
                            description:
                                'Amount updated successfully',
                        });
        
                        getCamperBookingDetail();
                    }
                })
            }

        }


        

        
    }


    const handleResendBookingEmail = () => {
        let params = { bookingId }

        resendBookingEmailService(params).then(res => {
            if(res.data.statusCode){
                notification.open({
                    message: 'Booking',
                    description: `Email sent successfully`,
                });
            }
        })
    }


    const checkHasCommuter = list => {
        if(list.length){
            let _commuterPrice = 0;
            for (let i = 0; i <= list.length; i++) {
                if(list[i]?.commuterPrice){
                    _commuterPrice = _commuterPrice + list[i].commuterPrice
                }
            }
            if(_commuterPrice > 0){
                return true;
            }else{
                return false; 
            }
        }else{
            return false; 
        }
    }



    const handleCloseModal = () => {
        setPaymentModal(false);
        setActivePaymentObject({});
        setActivePaymentObject2({});
        setCampRelatedProductsModal(false);

        let _campRelatedProducts = campRelatedProducts.map(j => ({
            ...j,
            selectColor: '',
            selectSize: '',
            selectColorErr: '',
            selectSizeErr: '',
            isSelected: false

        }));
        setCampRelatedProducts(_campRelatedProducts);
    }


    const getTaxs = () => {
        getCampDetailService(`?campCode=${props.campCode}`).then(res => {
            if(res?.data?.statusCode){
              let _data = res.data.responseData.result;
              setCreditCardRate(_data.creditCardRate || {});
              setAchTransferRate(_data.achTransferRate || {});
              setByCheque(_data.byCheque || {});
              setSplitPaymentRate(_data.splitPaymentRate || {});
              setShowInsurance(_data.showInsurance);
            }
        })
    }

    useEffect(() => {
        if(props.campCode && userId){
            getTaxs();
            getCards();
        }

    }, [props.campCode, userId, paymentModal])





    const handleValidate = () => {
        let validate = true;

        if(amount > 0){
            if(paymentMethod === 0){
                validate = false;
                setPaymentMethodErr('Please select payment method');
            }
    
            if(paymentMethod === 1){
                if(customerPaymentProfileId === '' || customerPaymentProfileId === 'undefined' || customerPaymentProfileId === null){
                    validate = false;
                    setCustomerPaymentProfileIdError('Please select card')
    
                }
            }


            if(paymentMethod === 3) {

                if(bankAccountType === '' || bankAccountType === 'undefined' || bankAccountType === null){
                    validate = false;
                    setBankAccountTypeErr('Account type is required')
                }

                if(bankAccountNum === '' || bankAccountNum === 'undefined' || bankAccountNum === null){
                    validate = false;
                    setBankAccountNumErr('Account number is required')
                }

                if(routingNumber === '' || routingNumber === 'undefined' || routingNumber === null){
                    validate = false;
                    setRoutingNumberErr('Routing number is required')
                }

                if(nameOnAccount === '' || nameOnAccount === 'undefined' || nameOnAccount === null){
                    validate = false;
                    setNameOnAccountErr('Account holder name is required')
                }

            }
        }

       

        return validate;
    }



 


    const getCards = () => {
        setLoader(true)
        setCardList([]);
        setHoldPrimaryId('');
        setPrimaryCardId('');
        let query = `?userId=${userId}&isWeb=1&campCode=${props.campCode}`
        getCustomerCardsService2(query).then(res => {
            if (res.data.statusCode) {
                if(res.data.responseData.result.length){
                    const cardList = res.data.responseData.result
                    setCardList(cardList);
                    setCustomerProfileId(cardList[0].card.signature);
                    let primaryCard = ''
                    for(let i =0; i < cardList.length ; i++){
                        if(cardList[i]?.card?.metadata?.primary){
                            primaryCard= cardList[i].card.token;
                        }else{
                            continue;
                        }
                    }
                    setPrimaryCardId(primaryCard)
                    setLoader(false)
                }else{
                    setLoader(false)
                    setIsNewCard(true);
                }
            } else {
                setLoader(false)
                setCardList([]);
            }
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            setLoader(false)
        })
    }






    const handleCreateBooking = () => {

        if (handleValidate()) {
            setBtnLoader(true);
            let params = {};

            

            if (paymentMethod === 1) {
                params = {
                    bookingId,
                    authPayment: {
                        paymentType: paymentMethod,
                        customerProfileId,
                        token: customerPaymentProfileId
                    },
                    payment: {
                        method: "Credit Card",
                        amount: getWithTaxPrice(creditCardRate, amount),
                        applicationFeeAmount : getTaxForPrice(creditCardRate, amount)
                    },
                    campCode: props.campCode
                }

                if(activePaymentObject._id){
                    params.emiId = activePaymentObject._id;
                    params.emiAmount = amount?.toFixed(2);
                    if(activePaymentObject.emiAmount === amount){
                        params.isFullPayment = true;
                    }
                }else{
                    params.remainingAmount = activePaymentObject2.remainingAmount - amount;
                }

            }


            if(paymentMethod === 3) {

                const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();

                params = {
                    isWeb: 1,
                    bookingId,
                    authPayment: {
                        account_owner_name: nameOnAccount,
                        routing_number: __routing_number,
                        account_number: __bankAccountNum,
                        account_type: bankAccountType,
                        account_owner_type: 'individual',
                        country: 'US',
                        currency: 'usd',
                        paymentType: 4
                    },
                    payment: {
                        method: 'Internet Banking',
                        amount: getWithTaxPrice(achTransferRate, amount),
                        applicationFeeAmount : getTaxForPrice(achTransferRate, amount)
                    },
                    campCode: props.campCode
                }

                if(activePaymentObject._id){
               

                    params.emiId = activePaymentObject._id;
                    params.emiAmount = amount?.toFixed(2);
    
                    if(activePaymentObject.emiAmount === amount){
                        params.isFullPayment = true;
                    }
                }else{
                    params.remainingAmount = activePaymentObject2.remainingAmount - amount;
                }
        
            }


            
    
            deductPaylaterAmountService(params).then(res => {
                let { status, data } = resHandle(res);
                setBtnLoader(false);
                if(status){
                    handleCloseModal();
                    getCamperBookingDetail();
                    notification.open({
                        message: 'Payments',
                        description:
                            'Payment create successfully',
                    });
                    
                } else {
                    setErrorMsg(res.data.error.errors.message || res.data.error.responseMessage);
                }
            })
        }  
        
    }



    const getProducts = () => {
        let query = `?&page=0&limit=100&type=2&itemType=1&programId=${programId}`;
        getProductsService(query).then(res => {
            if(res?.data?.statusCode){
                let _result = res.data.responseData.result;
                let _result2 = _result.map(i => ({
                    ...i,
                    count: 1,
                    selectedSize: '',
                    selectColor: ''
                }))
                setCampRelatedProducts(_result2);
                setCampRelatedProductCount(res.data.responseData.count);
            }
        })
    }

    useEffect(() => {
        getProducts()
    }, [])


    const handleStoreCount = (type, index) => {
        let _campRelatedProducts = [...campRelatedProducts];
        if(type === 'add'){
            _campRelatedProducts[index].count = _campRelatedProducts[index].count + 1
            
        }else{
            if(_campRelatedProducts[index].count !== 1){
                _campRelatedProducts[index].count = _campRelatedProducts[index].count - 1
            }
        }
        setCampRelatedProducts(_campRelatedProducts);
    }




    const handleRemoveToCartProduct = (item, _index) => {
        if(_index === -1){
            _index = activeProductIndex
        }
        let _campRelatedProducts = [...campRelatedProducts];
        _campRelatedProducts[_index].isSelected = false;
        _campRelatedProducts[_index].selectSize = '';
        _campRelatedProducts[_index].selectSizeErr = '';
        _campRelatedProducts[_index].selectColor = '';
        _campRelatedProducts[_index].selectColorErr = '';
        _campRelatedProducts[_index].count = 1;
        _campRelatedProducts[_index].subItems = item.subItems.map(item => ({...item,count:0 }));
        setCampRelatedProducts(_campRelatedProducts);


        let _passDataArr = [...passDataArr];

        let res = _passDataArr.filter(j => j.itemId !== item._id);
        setPassDataArr(res);
        const _cartId = _passDataArr.filter(j => j.itemId === item._id)[0]?.cartId;
        removeFromCart(`/?cartId=${_cartId}`).then(res => {
            if(res.data.statusCode){
                setActiveProductIndex(-1)
                notification.open({
                    description:
                        'Deleted successfully',
                        placement: 'topLeft'
                })
            }else{
                notification.open({
                    description:
                        'Something went wrong.',
                        placement: 'topLeft'
                })
            }
            handleAddCloseModal()
            
        });
    }


    const handleAddToCartProduct = (item) => {        
        let validate = true;
        let _campRelatedProducts = [...campRelatedProducts];
        const _index = _campRelatedProducts.indexOf(item)
        if(getSizes(activeAddProduct.sizes).length){
            if(selectedSize === '' || selectedSize === 'undefined' || selectedSize === null){
                setSelectedSizeErr('Please select size');
                validate = false;
            } 
        }

        if(getColors2(activeAddProduct.sizes).length){
            if(selectedColor === '' || selectedColor === 'undefined' || selectedColor === null){
                setSelectedColorErr('Please select color');
                validate = false;
            }
        }

        if(validate){
            let params = {
                itemId: item._id,
                color: selectedColor,
                sizes: selectedSize,
                count: currentCount,
                bookingId: bookingId,
                camperId: camperId,
                userId,
                programId, 
            }
            if(addons.length > 0){
                        
                params.subItems = addons.map(item => ({
                    count:item.count,
                    color:item.color,
                    price:item.price,
                    title:item.title,
                    itemId:item.itemId,
                    sizes:item.sizes,
                }))
                 
            }else {
                params.subItems =[];
            }

            addProductIntoCartService(params).then(res => {
                if(res.data.statusCode){
                    _campRelatedProducts[_index].isSelected = true;
                    let _passDataArr = [...passDataArr];
                    let _cartId
                    let _totalPrice
                    let _subItems
                    if(Array.isArray(res.data.responseData)){
                        _cartId =  res.data.responseData[0]._id
                        _totalPrice = item?.subItems?.length > 0 ?
                        (item.count * item.price) + res.data.responseData[0]?.items?.subItems.reduce((_total, i) =>  (i.count * i.price) + _total, 0)
                        : item.count * item.price;
                        _subItems =  [...res.data.responseData[0].items?.subItems]
                    }else{
                        _cartId = res.data.responseData._id
                        _totalPrice = item?.subItems?.length > 0 ?
                        (item.count * item.price) + (res.data.responseData.items.subItems.reduce((_total, i) =>  (i.count * i.price) + _total, 0)) 
                        : item.count * item.price;
                        _subItems =  [...res.data.responseData.items?.subItems]
                    }

                    _passDataArr.push({
                        cartId: _cartId,
                        itemId: item._id,
                        price: _totalPrice,
                        subItems:[..._subItems]
                    })
                    setPassDataArr(_passDataArr);
                 
                }else{
                    _campRelatedProducts[_index] = [...res.data.responseData[0].items?.subItems]
                    _campRelatedProducts[_index].isSelected = false;
                }
            })
            handleAddCloseModal()
        }

        setCampRelatedProducts(_campRelatedProducts);
       
    }


    const handleCheckout = () => {
            setProductCheckoutModal(true);
            handleCloseModal();
    }


    const handleBookingSubmit = () => {
        setProductCheckoutModal(false);
        getCamperBookingDetail();
        productListByBooking();
        notification.open({
            description: 'Booking created successfully',
        });
    }



    const productListByBooking = () => {
        let query = `?bookingId=${bookingId}&programId=${programId}&camperId=${camperId}`;

        productListByBookingSerivce(query).then(res => {
            if(res.data.statusCode){
                setUserProductList(res.data.responseData.result); 
                setPassDataArr([])
                getProducts()
            }
        })
    }


    useEffect(() => {
        if(bookingId){
            productListByBooking()
        }
    }, [bookingId])


    const handleProductCancelRefund = item => {
        setConfirmCancelProductModal(true);
        setActiveProductData(item);
        setProductRefundAmount(calculateProductRefundPrice(item));
    }






    const handleProductCancelRefundAction = () => {
        setBtnLoader(true)
        if(!productRefundAmount){
            setProductRefundAmountErr('Please enter refund amount')
            setBtnLoader(false)
            return
        }else if(productRefundAmount > calculateProductRefundPrice(activeProductData)){
            setProductRefundAmountErr('Refund amount must not be greater than the product amount.')
            setBtnLoader(false)
            return
        }else{
            let params = {
                bookingId,
                camperId,
                programId,
                itemId: activeProductData?.itemId,
                actionType: productCancellationType.value,
                purchaseId:activeProductData?.purchaseId
            }
            if(productCancellationType.value === 3 || productCancellationType.value === 4 ){
                params.amount = productRefundAmount
            }
            if(activeAddProduct?.sizes){
                params.sizes = activeAddProduct.sizes
            }
            if(activeAddProduct?.color){
                params.color = activeAddProduct.sizes
            }
            setConfimCancelBookingModal(false);
    
            cancelRefundAction(params).then(res => {
                if(res.data.statusCode){
                    notification.open({
                        description: `PRODUCT ${cancellationOptions[productCancellationType?.value -2].label} SUCCESSFUL`,
                    });
                }else{
                    notification.open({
                        description: 'Something went wrong. Please try Again.',
                    });  
                }
                setConfirmCancelProductModal(false);
                setProductCancellationType(cancellationOptions[0]);
                productListByBooking();
                setBtnLoader(false)
            })
        }
       
    }

    const getMedicalDocumentUrl = (data) => {
        if(data?.length){
            let _res = data.filter(i => (i !== ''));
            return _res[0]
        }else{
            return '';
        }
      
    }
    const handleUserStatusChange = () => {
            let params = {participantId: camperId, medicalDocumentStatus: medicalDocumentStatus === 1 ? 2 : 1}
            setMedicalDocumentStatus(medicalDocumentStatus === 1 ? 2 : 1);
            medicalDocumentStatusChangeSerivce(params).then(res => {
                if(res.data.statusCode){
                    if(res.data.responseData.result.medicalDocumentStatus === 1){
                        notification.open({
                            description: 'User approved successfully',
                        });
                    }else if(res.data.responseData.result.medicalDocumentStatus === 2){
                        notification.open({
                            description: 'User removed successfully',
                        }); 
                    }
                    
                }
            }).catch (error => {
                notification.open({
                    description: 'something went wrong',
                }); 
                console.error('Error during service worker registration:', error);
            })
    }

    const getNextPaymentDate = data => {
        let _filterd = data?.isEmi?.allEmiRemains?.filter(r_emis => r_emis.status === 2);

        if(_filterd.length){
            return moment(_filterd[0].emiDate).format('MMM DD, YYYY')
        }else {
            return '----'
        }

        
    }


    const updateBookingKey = () => {
        let params = { bookingId, programId }

        if(activeForm.type === 'keyDeposit'){
            params.keyDeposit = true;
            params.camperId = activeForm.camperId;
        }else if(activeForm.type === 'isDisclaimer'){
            params.isDisclaimer = true;
        }else{
            params.concussion = true;
            params.camperId = activeForm.camperId;
        }

        setActiveForm({})
        updateBookingKeyService(params).then(res => {
            if(res?.data?.statusCode){
                getCamperBookingDetail();
            }
        })
    }



    const handleFileChange = (e, camperId) => {
        if(e.target.files[0]){
            const data = new FormData();
            data.append('file', e.target.files[0]);
            uploadMedia(data).then((res) => {
                if(res.data.responseData.result.fileUrl){
                    let params = {
                        bookingId,
                        programId,
                        camperId,
                        medicalDocument: res.data.responseData.result.fileUrl
                    }
    
                    updateBookingKeyService(params).then(res => {
                        if(res?.data?.statusCode){
                            getCamperBookingDetail();
                        }
                    })
                }
            })
        }
        
    }
    const handleDeleteCard = (cardId) => {
        setIsDeletePopup(true); 
        setDeleteCardToken(cardId)
      }
      const deleteCardAction = () => {
        const params = {
            userId,
            cardToken :deleteCardToken,
        }
        //added api call to remove the card using card id. 
        deleteCardService(params).then( res => {
            if(res.status){
                getCards()
                setCardList(cardList.filter(card =>card.card.id !== deleteCardToken));
                setIsDeletePopup(false)
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsDeletePopup(false)
        }
        )
        
      }
      const handlePrimaryCardAction = () => {
        
            const params = {
                cardToken: holdPrimaryId,
                userId,
            }
            markPrimaryCard(params).then( res => {
            if(res.status){
                setIsSwitchPrimaryCard(false)
                getCards();
                setPrimaryCardId(holdPrimaryId);
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsSwitchPrimaryCard(false)
        }
        )
      }
      const handlePrimaryCard = (id) => {
            setHoldPrimaryId(id)
            setIsSwitchPrimaryCard(true)
      }


      const handleEMIDateChange = () => {
  
        if(selectEmiDate){
            let params = {
                bookingId,
                emiId: activeEMI._id,
                emiDate: new Date(removeTime(selectEmiDate._d)).getTime()
            }
            setUpdateEMIDateModal(false);
            setActiveEMI('');
            updateBookingDateService(params).then(res => {
                if(res.data.statusCode){
                    notification.open({
                        description: 'Date Updated successfully',
                    });
                    getCamperBookingDetail(); 
                } 
            })

        }else{

        }
      }



      const  disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }
  
     
    const showProductInfo = (pp) => {
        setActiveProduct(pp)
        setIsViewProducts(true);
     }
     const showProductPaymentInfo = (pp) => {
        setActiveProduct(pp)
        setPaymentInfoModal(true);
     }
    const handleCloseProductModal = () => {
        setIsViewProducts(false)
    }

    const addAddonsToList = (totalQuantity, addonId,selectedSize,selectedColor,) => {
        if(totalQuantity> 0 ){
            const addonItem = activeAddProduct?.subItems.filter(item => item.id === addonId)[0]
            const _newAddon = {
                count:totalQuantity,
                price:addonItem?.price,
                title:addonItem?.title,
                itemId: addonId,
            }
            if(selectedSize){
                _newAddon['sizes'] = selectedSize.value;
            }
            if(selectedColor){
                _newAddon["color"] = selectedColor?.value;
            }
            const _PreviousAddon = addons.filter(item => item.itemId !== addonId); 
            setAddons([..._PreviousAddon.filter(obj => Object.keys(obj).length !== 0 || obj.constructor !== Object), _newAddon])
        }
    }

    const handleShowModalProductDetail = (item, i) => {  
        setActiveProductIndex(i)   
        setIsViewAddProducts(true);
        const _isExistingItemDetails = getCartItemsProducts(item._id, item)
        if(!_isExistingItemDetails){
            setActiveAddProduct(item)
            setCurrentCount(item.count);
        }
        
    }
    const calculateTotalProductPrice = () => {
        const totalPrice = addons.reduce((totalPrice, addon ) => Number(totalPrice) + (Number(addon.count) * Number(addon.price)), activeAddProduct? activeAddProduct?.price * currentCount : 0) 
        return totalPrice;
    }

    const calculateTotalProductPrice1 = (item) => {
        const totalPrice = item.subItems.reduce((totalPrice, addon ) => Number(totalPrice) + (Number(addon.count) * Number(addon.price)), item? (item.price * item.count) : 0) 
        return totalPrice;
    }

    const handleAddCloseModal = () => {
        setIsViewAddProducts(false);
        setActiveAddProduct([])
        setAddons([])
        setSelectedColor('')
        setSelectedSize('')
        setSelectedColorErr('')
        setSelectedSizeErr('')
        setCurrentCount(1)
        setExistingWarning('')
        setActiveCartId('')
    }

    const handleAddToCartCountChange = (type) => {
        if(type === 'add'){
            setCurrentCount(currentCount + 1);     
        }else{
            if(currentCount !== 1){
                setCurrentCount(currentCount - 1);
            }
        }
        const _index = campRelatedProducts.indexOf(activeAddProduct);
        handleStoreCount(type , _index)
    }
    

    const isProductInCart = (id) => {
       return  passDataArr?.filter(item => item.itemId === id).length > 0 
    }

    const getSubItemDetails = (id) =>  {
        return passDataArr.find(p => p.itemId === id)
    }

    const getSubItemPrice = (id) =>  {
        if(passDataArr?.find(p => p?.itemId === id)){
            if(passDataArr.find(p => p.itemId === id)?.subItems?.length > 0){
                return passDataArr.find(p => p.itemId === id)?.subItems?.reduce((acc, item) => (acc + (item.price * item.count)), 0)
            }
        }
        return 0;
    }

    const isAllSubitemsCountNonZero = (id) =>  {
        if(passDataArr?.find(p => p?.itemId === id)){
            if(passDataArr.find(p => p.itemId === id)?.subItems?.length > 0){
                return passDataArr.find(p => p.itemId === id)?.subItems?.reduce((acc, item) => ((item.count > 0) || acc), false);
            }
        }
        return false;
    }
    const closeActiveQuestionModel = () => {
        setAnswerModal(false)
        getCamperBookingDetail();
    }
    const getCartItemsProducts = (itemId, item) => {
        setLoading(true);
        let _campRelatedProducts = [...campRelatedProducts];
        const _index = _campRelatedProducts.indexOf(item)
        let query =`?type=2&itemId=${itemId}`
        if(bookingId){
            query += `&bookingId=${bookingId}`
        }
        if(userId){
            query += `&userId=${userId}`
        }
        if(programId){
            query += `&programId=${programId}`
        }
        if(camperId){
            query += `&camperId=${camperId}`
        }
        getCartProductsService(query).then(response => {
            if(response?.data?.statusCode){
                if(response?.data?.responseData?.result?.length){
                    const _existingItemDetails = response?.data?.responseData?.result[0]
                    if(_existingItemDetails){
                        setExistingWarning("Imported from Parent's cart")
                        setCurrentCount(_existingItemDetails.count)
                        if(_existingItemDetails?.color){
                            setSelectedColor(_existingItemDetails.color)
                        }
                        if(_existingItemDetails?.sizes){
                            setSelectedSize(_existingItemDetails?.sizes)
                        }
                       setActiveCartId(_existingItemDetails.cartId);
                        const _subItems = [..._existingItemDetails?.subItems]
                        const _mergedArray = _subItems.map(item2 => {
                            const matchedItem = item.subItems.find(item1 => item1.id === item2.itemId);
                            return matchedItem ? { ...item2, 
                                                    ...matchedItem, 
                                                    selectedSize: item2?.sizes,
                                                    selectedColor: item2.color,
                                                } : item2;
                          });
                        if(_subItems.length > 0){                            
                            setAddons(_mergedArray)
                        }
                        item.subItems = [..._mergedArray]
                        item.count = _existingItemDetails.count
                        setActiveAddProduct(item)
                        _campRelatedProducts[_index] = item;
                        setCampRelatedProducts(_campRelatedProducts)
                        const _passDataArr = [...passDataArr]
                        _passDataArr.push({
                            cartId: _existingItemDetails.cartId,
                            itemId: _existingItemDetails.itemId,
                            price: calculateTotalProductPrice1(_existingItemDetails),
                            subItems:[..._existingItemDetails.subItems]
                        })
                        setPassDataArr(_passDataArr);
                    }
                    setLoading(false);
                    return true;
                }else {
                    setLoading(false);
                    return false
                }

            }
            return false
        }).catch(e =>  setLoading(false)).finally( () => setLoading(false));
    }

    const updateCartItemsProducts = (item) => {
        setLoading(true);
        let _campRelatedProducts = [...campRelatedProducts];
        const _index = _campRelatedProducts.indexOf(item)
        let data = {
            itemId: item._id,
            color: selectedColor,
            sizes: selectedSize,
            count: currentCount,
            bookingId: bookingId,
            camperId: camperId,
            cartId: activeCartId,
            userId,
            programId, 
        }
        if(addons.length > 0){              
            data.subItems = addons.map(item => ({
                count:item.count,
                color:Array.isArray(item.color) ? item.selectedColor : item.color,
                price:item.price,
                title:item.title,
                itemId:item.itemId,
                sizes:Array.isArray(item.sizes) ? item.selectedSize : item.sizes,
            }))
             
        }else {
            data.subItems =[];
        }


        updateCartProductsService(data).then(response => {
            if(response?.data?.statusCode){              
                let _passDataArr = [...passDataArr];
                let _totalPrice
               
                _totalPrice = item?.subItems?.length > 0 ?
                    (item.count * item.price) + addons.reduce((_total, i) =>  (i.count * i.price) + _total, 0)
                    : currentCount * item.price;
                const _mergedArray = addons.map(item2 => {
                    const matchedItem = item.subItems.find(item1 => item1.id === item2.itemId);
                    const { count, ...restOfItem2 } = item2
                    return matchedItem ? {...restOfItem2,
                                            ...matchedItem, 
                                            selectedSize: item2?.sizes,
                                            selectedColor: item2.color,
                                            count,
                                        } : item2;
                });
                let itemIndex = _passDataArr.findIndex(_item => _item.itemId === item._id)
                if(itemIndex !== -1){
                    _passDataArr[itemIndex] = {
                        cartId: activeCartId,
                        itemId: item._id,
                        price: _totalPrice,
                        subItems:[..._mergedArray]
                    }
                }else{
                    _passDataArr.push({
                        cartId: activeCartId,
                        itemId: item._id,
                        price: _totalPrice,
                        subItems:[..._mergedArray]
                    })
                }

                

                  item.count = currentCount 
                  item.subItems =[..._mergedArray]
                _campRelatedProducts[_index] = item
                setPassDataArr(_passDataArr); 
                setCampRelatedProducts(_campRelatedProducts);
                setLoading(false);
                handleAddCloseModal()
            }
        }).catch(e =>  setLoading(false)).finally( () => setLoading(false));
    }

    const handleProductActionModal = () => {
        setConfirmCancelProductModal(false)
        setProductCancellationType(cancellationOptions[0]);
        setActiveProductData({});
        setProductRefundAmount(0)
        setProductRefundAmountErr('')
    }

    useEffect(() => {
        if(payments?.length  && confimCancelBookingModal) {
            let _successfulPayment = []
            if( cancellationType.value === 3){  
                _successfulPayment =payments?.filter(py => {
                    if(!(py?.paymentStatus))  return true
                    return py.paymentStatus === "succeeded"
                })
                setPaymentList(_successfulPayment);
            }else if(cancellationType.value === 4){
                const _filteredpaymentList =   payments.filter(py => {
                    if(!(py?.paymentStatus)) return true;
                    const metadata =  py?.metadata;
                    if (!(metadata?.type)) return true;
                    return  metadata.type !== 'additionalCharge'; 
                })
               setPaymentList(_filteredpaymentList)
            }
            }
    }, [confimCancelBookingModal, cancellationType.value])


    const handleProductRefundAmount = e => {
        setProductRefundAmount(e)
        setProductRefundAmountErr('')
    }


    const handleAddInsurance = () => {
        if(programDetails?.startDate < Date.now()){
            return
        }
        setInsuranceModal(true)
    }

    const InsurancePurchaseCallback = () => {
        setInsuranceModal(false)
        getCamperBookingDetail()
    }

    const handleCancellationAction = e => {
        if(e.value ===5){
            handleCloseRefundCancelCamper();
            handleCamperChange(camperId)
            handleMetaDataChange(bookingId, allData.bookingNumber, programId)
            setChargeModal(true);
        }
       
        setSelectedTransaction({});
        setProgramRefundAmount(0);
        setCancellationType(e);
        setCancelRefundActionError('');
        }

    return(
        <div className="body_container pb-4 pt-5 camper_detail">
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="container">
                <div className="row">



                    <div className="col-lg-4">
                        <div className={`cm__card text-center ${allData?.campers?.status === 2 ? 'has_canceled' : ''}`}>
                        {allData?.campers?.status === 2 ? <span style={{right: '10px'}} className='has_canceled_text'>Canceled</span> : ''}
                        <Link className="cm_back_page" onClick={() => history.goBack()}><i className="icon-left-arrow" /> Back</Link>
                            <img src={allData?.campers?.profileImage ? allData?.campers?.profileImage : require('../../assets/img/user.png')} alt =""/>
                            <h5>{allData?.campers?.firstName} {allData?.campers?.lastName}</h5>
                            <p> Booking Number: {allData.bookingNumber} <br />
                                Booking Date: {convertTimeToCurrentTimeZone(allData.bookingDate, props?.userProfile?.timezone, 'MM/DD/YY hh:mm a')} <br />
                                Camp Program: {allData?.campers?.programName} <br />
                                Program Code: {allData?.campers?.programCode} <br />
                            </p>
                        </div>


                        <div className="cm__card">
                            <p><span className="color_black">Group Number :</span> 1</p>
                            <p><span className="color_black">Group Name :</span> Purple Bandits</p>
                            <p><span className="color_black">Staff Name :</span> <span className="color_red">Krystal , Mark</span></p>
                        </div>

                        <div className="cm__card">
                            <p><span className="color_black">Group Code :</span> <span className="color_red">{allData.groupCode}</span></p>
                        </div>


                        {
                            allData?.editNotes?.length ? <div className={`cm__card`}>
                            <h6>Payments Notes</h6>

                            {
                                allData?.editNotes.map((_note, i) => <div key={i} className='_note'>
                                    <p className={`${_note.diff ? '_note_dic' : '_note_plus'}`}>
                                        {_note.diff ? '+' : '-'} {_note.difference ? <b>${Math.abs(_note.difference).toFixed(2)}</b> : ''} {_note.notes}</p>
                                    </div>)
                            }
                        </div> : ''
                        }



                        <div className="cm__card">
                            
                            {
                                allData?.finalPayAmountReason && 
                                <>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                        <Tooltip>
                                            <ul>
                                                <li>Final Confirmed Amount: ${allData?.finalPayAmount}</li>
                                                <li>Reason: {allData?.finalPayAmountReason}</li>
                                            </ul>
                                        </Tooltip>}>
                                        <span className='my_tooltip'><InformationIcon color="#842014"/></span>
                                    </OverlayTrigger>
                                    <hr/>
                                </>
                            }
                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Coupon Code Value:</span> {allData?.couponValue ? `$${allData?.couponValue?.toFixed(2)}` : 'No'}</p>
                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Gift Card Value:</span> {allData?.giftCardValue ? `$${allData?.giftCardValue?.toFixed(2)}` : 0}</p>
                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Coupon Code Applied:</span>{allData?.couponValue ? `${allData?.couponCode}` : 0}</p> 
                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Apply Discount:</span>{allData?.couponValue ? `$${allData?.couponValue.toFixed(2)}` : 0}</p>
                            {allData?.campers?.isCommuter ? <p className='d-flex justify-content-between align-items-center'><span className="color_black">Program Day Only Fee:</span> ${allData?.campers?.commuterPrice?.toFixed(2)}</p> : <p className='d-flex justify-content-between align-items-center'><span className="color_black">{programPriceText?.label }:</span> {allData?.campers?.registrationFee ? `$${programPriceText?.value?.toFixed(2)}` : 0}</p>}
                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Convenience Fee:</span> {allData?.campers?.convenienceFee ? `$${allData?.campers?.convenienceFee?.toFixed(2)}` : 0}</p>
                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Non-refundable Fee:</span> {allData?.campers?.registerNonRefundFee ? `$${allData?.campers?.registerNonRefundFee?.toFixed(2)}` : 0}</p>
                            <hr />
                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Booking Fee:</span> {allData?.totalAmount ? `$${allData?.totalAmount.toFixed(2)}` : `$${allData?.payment?.amount.toFixed(2)}` || 0}</p>
                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Insurance:</span> {allData?.payment?.amount ? calculateInsuranceCamperDisplay(allData?.insurance, allData?.campers?.insuranceData) ?`$${calculateInsuranceCamperDisplay(allData?.insurance, allData?.campers?.insuranceData)?.toFixed(2)}` : "NONE" : 0}</p>
                        

                            {allData?.isEmi?.allEmiRemains?.length ? 
                                allData?.isEmi?.allEmiRemains?.filter(r_emis => (r_emis.status === 2) || (r_emis.status === 3)).map(_emi => <div className='mt-4 mb-4' key={_emi._id}>
                                    <p className='d-flex m-0 justify-content-between align-items-center'><span className="color_black">Due: {moment.utc(_emi.emiDate).format('DD MMM, YYYY')} <i className='icon-pancil' onClick={() => (setUpdateEMIDateModal(true), setActiveEMI(_emi))} /></span> {_emi?.emiAmount ? `$${_emi?.emiAmount.toFixed(2)}` : 0} </p>
                                    <div className='d-flex justify-content-between'>
                                        <span onClick={() => (setAmountUpdateModal(true), setRemainingAmount(_emi.emiAmount), setEmiId({..._emi, remainingAmount: allData?.remainingAmount}))} className='btn btn-dark btn-sm'>Update Amount</span>
                                        {(_emi.status === 3) && <GenericTooltip type="warning" tooltipText={_emi?.transactions?.length > 0 ? `Reason : ${_emi?.transactions?.at(-1)?.errorMessage ? _emi?.transactions?.at(-1)?.errorMessage: "Last payment failed"}`: "Reason: Last payment failed"} icon={<WarningOutlined style={{ fontSize: '20px', color: 'orange' }}/>}/>}
                                        <span className='btn btn-primary btn-sm' onClick={() => (setActivePaymentObject(_emi), setPaymentModal(true), setAmount(_emi?.emiAmount))}>Pay Now</span>
                                    </div>
                                </div>) : allData?.remainingAmount ? <Fragment>
                                    <p className='d-flex justify-content-between align-items-center'><span className="color_black">Remaining Amount: </span> {allData?.remainingAmount ? `$${allData?.remainingAmount.toFixed(2)}` : 0}</p>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span onClick={() => (setAmountUpdateModal(true), setRemainingAmount(allData.remainingAmount), setActivePaymentObject2(allData))} className='btn btn-dark btn-sm'>Update Remaining Amount</span>
                                        <span className='btn btn-primary btn-sm' onClick={() => (setActivePaymentObject2(allData), setPaymentModal(true), setAmount(allData?.remainingAmount))}>Pay Now</span>
                                    </div>
                                </Fragment> : ''
                            }

                        
                        </div>

                        <div className={`cm__card ${(allData?.campers?.isCancelled || allData?.campers?.status === 2 || allData?.campers?.status === 4 )? 'has_canceled' :
                             ''} ${(allData?.campers?.status === 3 || allData?.campers?.status === 4) ? 'has_refunded' : ''}`}>

                        <div className={`d-flex align-items-center justify-content-end pt-3`}>
                            <span className='btn btn-primary d-flex align-items-center' onClick={() => setConfimCancelBookingModal(true)}> Edit <i className='icon-setting ml-2'/></span>
                            {(allData?.campers?.isCancelled || allData?.campers?.status ===2 || (allData?.campers?.status === 4))? <span style={{left: '10px', color:'red'}} className='has_canceled_text'>Canceled</span> : ''}
                            {(allData?.campers?.status === 3 || (allData?.campers?.status === 4)) ? <span style={{right: '10px'}} className='has_canceled_text'>Refunded</span> : ''}

                        </div>


                            {
                               payments.length > 0 ? <div className='mt-4'>

                                {
                                    payments.map(pArr => (
                                        <div className='p_arr'>
                                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Payment Date :</span> {convertTimeToCurrentTimeZone(pArr.paymentDate,props?.userProfile?.timezone, PaymentDateFormat) || 'N/A'}</p>
                                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Transaction Id:</span> {pArr.transaction_id || 'N/A'}</p>
                                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Payment Method:</span> {pArr.method}</p>
                                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Amount:</span> ${pArr.amount.toFixed(2)}</p>
                                                {
                                                            pArr?.paymentStatus === 'failed' ? 
                                                            <>
                                                            <p style={{ fontSize: "12px" , color:'red'}}><i>{pArr?.failed?.errorDescription}   </i></p>
                                                            <p style={{color:'red'}} className='d-flex justify-content-between align-items-center'> <span className='p_cat_text' style={{background:'red'}}>Payment Failed</span> 
                                                            {convertTimeToCurrentTimeZone(pArr?.failed?.paymentDate,props?.userProfile?.timezone, PaymentDateFormat)}
                                                            </p>
                                                           
                                                            </>
                                                            : ''

                                                } 
                                                {      pArr?.refunds?.length > 0  ?
                                                                <>
                                                                  <span className='p_cat_text' style={{background:'blue'}}>Payment Refunded</span>
                                                                    {
                                                                        pArr?.refunds?.map( rf =>
                                                                            <>  
                                                                                <div style={{color:'blue'}} className='d-flex justify-content-between align-items-center mb-0'>
                                                                                    <span>Refunded Amount : $ {rf?.amount}</span>
                                                                                    {rf.status === 'pending' && <span className = 'mt-0' style={{fontSize:"10px"}}><i>(Pending)</i></span>}
                                                                                    <span>
                                                                                         {convertTimeToCurrentTimeZone(rf?.paymentDate, props?.userProfile?.timezone,PaymentDateFormat)}
                                                                                    </span>
                                                                                </div>
                                                                                
                                                                                
                                                                            </>
                                                                    )
                                                                    }
                                                                </>
                                                                : (pArr?.paymentStatus === 'succeeded' ? <span className='p_cat_text' style={{background:'green'}}>Payment successful</span> 

                                                                : '')

                                                }
                                        </div>
                                    ))
                                }

                               </div> : <div className='mt-4'>
                                                {/* <p className='d-flex justify-content-between align-items-center'>
                                                    <span className="color_black">Card Info:</span>
                                                    <div className='card_info'>
                                                        <div className="p_card_img ml-4">
                                                            <img src={allData?.card?.brand ? require(`../../assets/img/${getCardTypeName(allData?.card?.brand)}.svg`) : require(`../../assets/img/Visa.svg`)} />
                                                        </div>
                                                        <p>****{allData?.card?.acct_last_four}</p>
                                                    </div>
                                                </p> */}

                                                <p className='d-flex justify-content-between align-items-center'><span className="color_black">Payment Type:</span> {allData?.payment?.method || 'N/A'}</p>
                                                <p className='d-flex justify-content-between align-items-center'><span className="color_black">Transaction Id:</span> {allData?.payment?.transaction_id || 'N/A'}</p>
                                                <p className='d-flex justify-content-between align-items-center'><span className="color_black">Amount Paid:</span> {allData?.payment?.amount ? `$${allData?.payment?.amount.toFixed(2)}` : 0}</p>
                               </div> 
                            }


                            {
                                allData?.isEmi?.allEmiRemains?.length ? (
                                    <Fragment>
                                        {/* <p className='d-flex justify-content-between align-items-center'><span className="color_black">Left to Pay:</span> {allData?.giftCardValue ? 'Yes' : 'No'}</p> */}
                                        <p className='d-flex justify-content-between align-items-center'><span className="color_black">Date of Next Payment:</span> {getNextPaymentDate(allData)}</p>
                                        <p className='d-flex justify-content-between align-items-center'><span className="color_black"># Of Payment Left</span> {allData?.isEmi?.remaingMonth ? allData?.isEmi?.remaingMonth : 0}</p>
                                    </Fragment>
                                ) : ''
                            }
                        
                        </div>


                        <ul className="booking_users booking_users2">
                            <li>
                                <h6>{allData?.campers?.firstName} {allData?.campers?.lastName || ''}</h6>
                                {
                                    allData?.campers?.isCommuter ? '' : (
                                        <div className='get_forms'>
                                            <p>Key Deposit</p>
                                            {allData?.campers?.keyDeposit ? <span onClick={() => setActiveForm({viewType: true, camperId: camperId, type: 'keyDeposit', url: require('../../assets/pdf/key-deposit.pdf')})} className="updated_icon"><img src={require('../../assets/img/check_icon.png')} /> Updated</span>  : <span onClick={() => setActiveForm({camperId: camperId, type: 'keyDeposit', url: require('../../assets/pdf/key-deposit.pdf')})} className="btn btn-secondary btn-sm">View</span>}
                                        </div>
                                    )
                                }
                                

                                <div className='get_forms'>
                                    <p>Concussion Waiver Document </p>
                                    {allData?.campers?.concussion ? <span onClick={() => setActiveForm({viewType: true, camperId: camperId, type: 'concussion', url: require('../../assets/pdf/concussion-protocol.pdf')})} className="updated_icon"><img src={require('../../assets/img/check_icon.png')} /> Updated</span>  : <span onClick={() => setActiveForm({camperId: camperId, type: 'concussion', url: require('../../assets/pdf/concussion-protocol.pdf')})} className="btn btn-secondary btn-sm">View</span>}
                                </div>

                                <div className='get_forms'>
                                    <p>Attach medical clearance form & physical </p>
                                    {getMedicalDocumentUrl(allData?.campers?.medicalDocument) ? <span onClick={() => setActiveForm({viewType: true, camperId: camperId, type: 'concussion', url: getMedicalDocumentUrl(allData?.campers?.medicalDocument)})} className="updated_icon"><img src={require('../../assets/img/check_icon.png')} /> Updated</span>  : <span className="btn btn-outline-secondary btn-sm" style={{position: 'relative'}}>Attach PDF <input type='File' onChange={e => handleFileChange(e, camperId)} className='mm_file' /></span>}
                                </div>
                            </li>
                        </ul>

    

                        <div className='booking_users booking_users2 d-flex justify-content-between align-items-center'>
                            <p className='m-0'>Waiver Disclaimer</p>
                            {allData?.isDisclaimer ? <span className="updated_icon"><img src={require('../../assets/img/check_icon.png')} /> Updated</span>  : <span className="btn btn-outline-secondary btn-sm">View </span>}
                        </div>
                    </div>




                    <div className="col-lg-8">
                        <div className="cm__card">
                            <div className='d-flex align-items-center justify-content-between'>
                                <h4 className="color_red">Legal Guardian - </h4>
                              <div>
                              <button onClick={handleResendBookingEmail} className="btn btn-dark btn-sm mr-3">Resend Booking Email</button>
                                <Link to={`/users/detail/${allData.userId}`} className="btn btn-primary btn-sm">User Detail</Link>
                                
                              </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <p><span className="color_black">Name :</span> {allData?.campers?.UserType ? allData.campers.primaryName : 'Guest User'}</p>
                                    <p><span className="color_black">Primary Phone Number :</span> {allData?.campers?.primaryPhone} </p>   
                                    <p><span className="color_black">Secondary Phone Number :</span> {allData?.campers?.secondaryPhone ? allData?.campers?.secondaryPhone : 'N/A'} </p>   
                                    <p><span className="color_black">Signature Line :</span> {getSignature(allData?.campers?.signatureLine)}</p>   
                                    <p><span className="color_black">Signature :</span> {allData?.campers?.signatureImage ? <img className='signature_image' src={allData?.campers?.signatureImage} /> : 'N/A'} </p>   
                                </div>
                                <div className="col-lg-6">
                                    <p><span className="color_black">Email :</span> {allData?.campers?.email}</p>   
                                    <p><span className="color_black">Secondary Contact Name :</span> {allData?.campers?.secondaryContact ? allData?.campers?.secondaryContact : 'N/A'} </p>   
                                    <p><span className="color_black">Secondary Email :</span> {allData?.campers?.secondaryContact ? allData?.campers?.secondaryEmail : 'N/A'} </p>   
                                </div>
                            </div>



                            <h4 className="color_red">Participant Information -</h4>

                            <div className="row">
                                <div className="col-lg-6">
                                    <p><span className="color_black">Age :</span> {allData?.campers?.dob ? _calculateAge(allData.campers.dob) : ''}</p>
                                    <p><span className="color_black">Gender : </span> 
                                                                            {allData?.campers?.gender === 1 ? 'Male' : ''}
                                                                            {allData?.campers?.gender === 2 ? 'Female' : ''}
                                                                            {allData?.campers?.gender === 3 ? 'Non-Binary' : ''}</p>
                                    {
                                        allData.UserType ? <p><span className="color_black">Activity Interest :</span> {props.category.filter(el => allData?.campers?.activityInterest.find(i => el._id === i.category) && el).map(n => n.name).join(', ')}</p> : ''
                                    }

                        
                                    
                                    <p><span className="color_black">Grade :</span> {allData?.campers?.currentGrade || 'N/A'}</p>
                                    <p><span className="color_black">Camp Name :</span> {allData?.campName || 'N/A'}</p>
                                    <p><span className="color_black">T-shirt Size :</span> {allData?.campers?.tshirtSize || 'N/A'}</p>
                                    <p><span className="color_black">Medications and Dosage, and special Assistance:</span> {allData?.campers?.medicationsTaken?.trim() || 'N/A'}</p>

                                    <p><span className="color_black">Address :</span> {allData?.campers?.address?.trim() || 'N/A'}</p>
                                    <p><span className="color_black">Apartment :</span> {allData?.campers?.apartment?.trim() || 'N/A'}</p>
                                    <p><span className="color_black">City :</span> {allData?.campers?.city?.trim() || 'N/A'}</p>
                                    <p><span className="color_black">Participant Bringing<br />Own Medication:</span> {allData.campers?.participantBringingOwnMedication === 1 ? 'Yes' : 'No'}</p>
                                    {allData?.campers?.byDates === 1 ? checkHasCommuter(allData?.campers?.additionalDates) ? <p><span className="color_black">Is Commuter :</span> {allData.campers?.isCommuter ? 'Yes' : 'No'}</p> : '' : ''}
                                    {allData?.campers?.byDates !== 1 ? allData?.campers?.commuterPrice ? <p><span className="color_black">Is Commuter :</span> {allData.campers?.isCommuter ? 'Yes' : 'No'}</p> : '' : ''}
                                    
                                    

                                </div>
                                <div className="col-lg-6">
                                    <p><span className="color_black">Shorts Size :</span> {allData?.campers?.shortsSize || 'N/A'}</p>
                                    <p><span className="color_black">Medical :</span> {allData?.campers?.medical ? removeStr(allData?.campers?.medical) : 'N/A'}</p>
                                    <p><span className="color_black">Dietary Guidelines :</span> {allData?.campers?.dietaryGuidelines ? removeStr(allData?.campers?.dietaryGuidelines) : 'N/A'}</p>
                                    <p><span className="color_black">Diet is Religious :</span> {allData?.campers?.dietReligious === 1 ? 'Yes' : 'No'}</p>
                                    <p><span className="color_black">Allergy :</span> {allData?.campers?.allergy ? removeStr(allData?.campers?.allergy) : 'N/A'}</p>
                                    <p className="color_red">Allergy is deadly : {allData?.campers?.allergyDeadly === 1 ? 'Yes' : 'No'}</p>
                                    <p><span className="color_black">Video / Digital Photography :</span> {allData?.campers?.allowDocumentCamper === 1 ? 'Yes' : 'No'}</p>
                                    <p><span className="color_black">State :</span> {allData?.campers?.state || 'N/A'}</p>
                                    <p><span className="color_black">Zipcode :</span> {allData?.campers?.zipCode || 'N/A'}</p>
                                    <p><span className="color_black">Participant Receive<br />Counter Medication:</span> {allData.campers?.participantReceiveCounterMedication === 1 ? 'Yes' : 'No'}</p>
                                    <p><span className="color_black">Permission<br />Emergency Medication:</span> {allData.campers?.permissionEmergencyMedication === 1 ? 'Yes' : 'No'}</p>

                          
                                </div>
                            </div>
                            
                            



                            <hr />

                            <h4 className="color_red">Insurance Information -</h4>

                            <div className="row">
                                <div className="col-lg-6">
                                    <p><span className="color_black">Medical Insurance Company :</span> {allData?.campers?.medicalInsuranceCompany || 'N/A'}</p>
                                    <p><span className="color_black">Policy Number :</span> {allData?.campers?.policyNumber || 'N/A'}</p>
                                    <p><span className="color_black">Group # :</span> {allData?.campers?.group || 'N/A'}</p>
                                </div>
                                <div className="col-lg-6">
                                <p><span className="color_black">Primary Physician :</span> {allData?.campers?.primaryPhysician?.trim() || 'N/A'}</p>
                                    <p><span className="color_black">Physician Address :</span> {allData?.campers?.physicianAddress?.trim() || 'N/A'}</p>
                                    <p><span className="color_black">Physician Phone # :</span> {allData?.campers?.physicianPhone?.trim() || 'N/A'} </p>
                                </div>
                            </div>


                         
                            {
                                allData?.campers?.uslCustomerEntity?.uslCustomerEntity?.length ? <Fragment>
                                       <hr />
                                    <h4 className="color_red">USL Customer Information -</h4>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <p><span className="color_black">First Name :</span> {allData?.campers?.uslCustomerEntity?.uslCustomerEntity[0]?.FirstName}</p>
                                            <p><span className="color_black">Last Name :</span> {allData?.campers?.uslCustomerEntity?.uslCustomerEntity[0]?.LastName}</p>
                                            <p><span className="color_black">USL Member ID :</span> {allData?.campers?.uslCustomerEntity?.uslCustomerEntity[0]?.USLMemberID}</p>
                                            <p><span className="color_black">Expiration Date :</span> {allData?.campers?.uslCustomerEntity?.uslCustomerEntity[0]?.ExpirationDate}</p>
                                            <p><span className="color_black">Email :</span> {allData?.campers?.uslCustomerEntity?.uslCustomerEntity[0]?.Email}</p>
                                        </div>
                                    </div>

                                </Fragment> : ''
                            }

                            {
                                allData?.campers?.questions?.length ? <Fragment>
                                    <div className='booking_ans'>
                                        <h4 className="color_red">Questions </h4>
                                        <i className="icon-pancil" onClick={() => handleShowEditModal(allData?.campers?.questions)} title='Edit Answer' />
                                    </div>
                                    {
                                        allData?.campers?.questions?.map((item, i) => <div className='booking_ans' key={item._id}>
                                            <p> <span className="color_black">Question {i + 1}: {item.title}</span></p>
                                            {
                                                (item.questionType === 3 || item.questionType === 4)? <Fragment>
                                                    {Array.isArray(item.answer) && item.answer.length  ?   item?.answer?.map(answ => <span className='ans_chip'>{answ}</span> ) : 
                                                    (!Array.isArray(item.answer)) ? item.answer?.replace(/,\s*$/, '').split(",").map(answ => <span className='ans_chip'>{answ}</span>) : ""}
                                                </Fragment> : <p><span className="color_black">Answer: {item.answer}</span></p>
                                            }

                                            </div>)
                                    }
                                    
                                </Fragment> : ''
                            }

                            
                            <div className="row">
                                <div className="col-lg-12">
                                    {additionalExperience.length ? <h4 className="color_red">Additional Experience -</h4> : ''}
                                    {
                                        additionalExperience.map(addit => <div className="cm_addit">
                                        <p><b>Title</b></p>
                                        <p>{getHtml(addit.title)}</p>
                                        <p><b>Number Purchased:</b> {addit.numberOfBooking}</p>
                                        <p><b>price:</b> ${parseInt(addit.price).toFixed(2)}</p>
                                    </div>)
                                    }
                                </div>
                            </div>

                            <hr />



                            {
                                allData?.questions?.length ? <Fragment>
                                    {
                                        allData.questions.map(item => <Fragment key={item._id}>
                                            <h4 className="color_red">Question: {item.question}</h4>
                                            <p><span className="color_black">Answer: {item.answer}</span></p>
                                        </Fragment>)
                                    }
                                    
                                </Fragment> : ''
                            }

                    {          
                        <>
                                <h4 className="color_red">Purchased Insurance Information -</h4>
                            
                                <>
                                    {allData?.campers?.insuranceData?.length > 0 &&  allData?.campers?.insuranceData?.filter(ins => ins.insuranceStatus !== 2).length > 0?
                                        allData?.campers?.insuranceData?.filter(ins => ins.insuranceStatus !== 2).map( (ins, i) =>
                                        <>
                                            <h6 className="color_red">{i +1}. {ins?.product?.friendly_name}</h6>
                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <p><span className="color_black">Policy Number :</span> { ins?.policyNumber}</p>
                                                    <p><span className="color_black">Status :</span> Purchased</p>
                                                    <p><span className="color_black">Premium Amount : </span><strong>${ins?.total}</strong></p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <p><span className="color_black">Effective Date :</span> {ins?.effective_date.split('T')[0]}</p>
                                                    <p><span className="color_black">Expiration Date :</span> {ins?.expiration_date.split('T')[0]}</p>
                                                </div>
                                            
                                            </div>
                                        </>
                                        ):<span className="color_red">Insurance information is not available.</span>
                                    }
                                    {
                                        showInsurance?.length > 0 && showInsurance?.filter(i => i.isShowInsurance === true)?.length > 0  && 
                                        <>
                                        { addedinsurance?.length === MAX_INSURANCE_PRODUCTS ? '' :
                                            (programDetails?.startDate > Date.now())  ? 
                                                <div className='col-12'>
                                                    <div className='d-flex align-items-center justify-content-end'>
                                                        <span onClick={handleAddInsurance} className="btn btn-primary btn-sm px-4">Add Insurance</span>
                                                    </div>
                                                </div>
                                            : <h6 className="color_red">Note : Insurance can not be purchased after the program start date.</h6>
                                            }
                                        </>
                                    }
                                    <hr />                                  
                                </>
                        </>
                            
                            }

                    <div className='approve_switch'>
                        <span style={{marginRight:"10px"}}>Approved</span>
                            <div  className="cm_swich_wrap">
                            <input  onChange={handleUserStatusChange} checked={medicalDocumentStatus === 1} type="checkbox" />
                            <span/>
                            </div>
                        </div>


                            <h4 className="color_red">PDF File</h4>
                            {allData?.campers?.gardianPdf || allData?.campers?.hippaDocument || allData?.campers?.medicalDocument || allData?.campers?.waiver || allData?.campers?.waiverLiability ? <Fragment>
                            {allData?.campers?.gardianPdf ? <div className="gardianPdf"><a target="_blank" href={allData.campers.gardianPdf}><PDFIcon /> PDF Uploaded</a></div> : ''}
                            {allData?.campers?.hippaDocument ? <div className="gardianPdf"><a target="_blank" href={allData.campers.hippaDocument}><PDFIcon />Hippa Document</a></div> : ''}
                            {allData?.campers?.medicalDocument.filter(j => j.length > 1).map((m, i) => <div key={i} className="gardianPdf"><a target="_blank" href={m}><PDFIcon />Medical Document {i + 1}</a></div>)}
                            {allData?.campers?.waiver ? <div className="gardianPdf"><a target="_blank" href={allData.campers.waiver}><PDFIcon />TCL Waiver</a></div> : ''}
                            {allData?.campers?.waiverLiability ? <div className="gardianPdf"><a target="_blank" href={allData.campers.waiverLiability}><PDFIcon />Waiver of Liability for COVID-19 Student Sport or Activity</a></div> : ''}
                            </Fragment> : 'N/A'}



                            <div className='row'>

                                <div className='col-12'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className="color_red">Products</h4>
                                        <span onClick={() => setCampRelatedProductsModal(true)} className="btn btn-primary btn-sm px-4">Add Products</span>
                                    </div>
                                </div>



                                {
                                userProductList?.length ? userProductList.map(pp => (
                                    <div className='col-md-4' key={pp.ItemId}>
                                        <div className={`product_card ${(pp?.itemStatus === 3 || pp?.itemStatus === 2 || pp.status === 2  || pp?.paymentArr?.at(-1)?.paymentStatus === 'failed')? 'has_canceled' : ''} ${(pp.status === 3 || pp?.itemStatus === 4) ? 'has_refunded' : ''}`}>   
                                        {pp.status === 2 && <span className='has_canceled_product_text'>canceled</span> }
                                        {pp.status === 3 && <span className='has_canceled_product_text' style={{color: 'green'}}>Refunded</span> }                                     
                                            {
                                                pp?.image?.length ? <Slider {...sliderSetting}>
                                                                        {pp?.image.map(img => (
                                                                                <img 
                                                                                    src={img} className="img-fluid pointer pp_img" 
                                                                                    key ={uuidv4()} 
                                                                                    onClick={() => showProductInfo(pp)}
                                                                                    alt=''
                                                                                />
                                                                        ))}
                                                                    </Slider> : ''
                                            }

                                    
                                            <div className='product_text mt-2'>
                                                <h3 style={{color: pp.textColor || '#000'}}>{pp.name}</h3>
                                                {pp.purchaseType === 2 && <span>Purchased with Program.</span>}
                                            </div>
                                            
                                            <div className='user_product_price'>
                                                <div className='mb-3'>
                                                    {pp?.itemStatus === 2 && <span className='text-danger'>canceled</span> }
                                                    {pp?.itemStatus === 3 && <span className='text-warning'>canceled  & Refunded</span> }
                                                    {pp?.itemStatus === 4 && <span className='text-primary'>Refunded</span> }  
                                                    <h6 className='m-0'>Item Price: ${pp?.itemPrice?.toFixed(2)}</h6>
                                                    <h6 className='m-0'>Quantity: {pp?.quantity}</h6>
                                                    {pp?.camperName ? <h6 className='m-0'>Camper: {pp.camperName}</h6> : ''}
                                                   
                                                    {/* <h6 className='m-0'>Total Price: ${pp?.paymentPrice?.toFixed(2)}</h6> */}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span className='btn btn-primary d-flex align-items-center' onClick={() => handleProductCancelRefund(pp)}>Edit <i className='icon-setting ml-1'/></span> 
                                                    {pp.purchaseType === 1 && <span onClick={() => showProductPaymentInfo(pp)} className='btn btn-dark btn-sm'>Payment History</span>}
                                                </div>
                                            </div>

                                        

                                            
                                        </div>
                                    </div>
                                )) : ''
                                }

                                </div>

                        </div>
                    </div>




                </div>
            </div>


            <Modal show={confimCancelBookingModal} centered> 
                            <Modal.Title>
                                <span className="modal_close" onClick={handleCloseRefundCancelCamper}><i className="icon-cross" /></span>
                            </Modal.Title>
                                <Modal.Body>
                                    <div className='text-center p-4'>
                                        {programRefundAmount ? (cancellationType.value === 3 || cancellationType.value === 4) &&  <h4>Refundable amount: ${programRefundAmount.toFixed(2)}</h4> : ''}
                                        <div className="form-group">
                                        <label>Select Action Type</label>
                                            <ReactSelect
                                                className="form-control ml-3"
                                                placeholder="Select Action Type"
                                                classNamePrefix="cm_select"
                                                value={cancellationType}
                                                onChange={handleCancellationAction}
                                                options={(allData?.payment?.method === 'Internet Banking' || 
                                                    allData?.payment?.method === 'Credit Card') || allData?.payment?.method === 'Split Payment' ?
                                                    camperCancellationOptions :   
                                                    camperCancellationOptions?.filter((_, i) => i === 0 || i === 3)
                                                }
                                            />
                                           {CancelRefundActionError &&  <span className='text-danger mt-2 ml-3'>{CancelRefundActionError}</span>}
                                        </div>
                                        {(cancellationType.value === 3 || cancellationType.value === 4) &&
                                            <div className="form-group">
                                            <label>Select Transaction to refund</label>
                                                <ReactSelect
                                                    className="form-control ml-3"
                                                    placeholder="Select Transaction"
                                                    classNamePrefix="cm_select"
                                                    onChange={e => (handleRefundTransaction(e))}
                                                    options={paymentList ? paymentList.map(p => (
                                                        {label: `Amount: $${p.amount} - Date: ${moment.utc(p.paymentDate).format('MMM DD, YYYY')}`, value:p.transaction_id}))
                                                        : [{label: "No transaction Available", value: 0}]}
                                                />
                                            </div>
                                        }
                                        {
                                            selectedTransaction?.transaction_id && 
                                            <div className='p_arr ml-4 '>
                                                <p className='d-flex justify-content-between align-items-center'><span className="color_black">Payment Date :</span> {convertTimeToCurrentTimeZone(selectedTransaction?.paymentDate,props?.userProfile?.timezone, PaymentDateFormat) || 'N/A'}</p>
                                                <p className='d-flex justify-content-between align-items-center'><span className="color_black">Transaction Id:</span> {selectedTransaction?.transaction_id || 'N/A'}</p>
                                                <p className='d-flex justify-content-between align-items-center'><span className="color_black">Payment Method:</span> {selectedTransaction?.method}</p>
                                                <p className='d-flex justify-content-between align-items-center'><span className="color_black">Amount:</span> ${selectedTransaction?.amount.toFixed(2)}</p>
                                                {      selectedTransaction?.refunds?.length > 0  ?
                                                                <>
                                                                <div className='d-flex justify-content-between'>
                                                                  <span className='p-2' style={{background:'blue'}}>Payment Refunded</span>
                                                                </div>
                                                                    {
                                                                        selectedTransaction?.refunds?.map( rf =>
                                                                            <>  
                                                                                <div style={{color:'blue'}} >
                                                                                <p className='d-flex justify-content-between align-items-center'><span >Refund Issue Date :</span> 
                                                                                    {convertTimeToCurrentTimeZone(rf?.paymentDate, props?.userProfile?.timezone, PaymentDateFormat) || 'N/A'}
                                                                                </p>
                                                                                <p className='d-flex justify-content-between align-items-center'><span >Refunded Amount:</span> ${rf?.amount.toFixed(2)}</p>
                                                                                <p className='d-flex justify-content-between align-items-center'><span >Refund Status:</span>
                                                                                {
                                                                                    rf.status === 'pending' ? 
                                                                                    <span className = 'mt-0' style={{fontSize:"10px"}}>
                                                                                        <i>Pending</i>
                                                                                    </span>
                                                                                    :
                                                                                    <span className = 'mt-0' style={{fontSize:"10px"}}>
                                                                                        <i>Successful</i>
                                                                                    </span>
                                                                                }
                                                                                </p>
                                                                                <hr/>
                                                                                </div>
                                                                                
                                                                                
                                                                            </>
                                                                    )
                                                                    }
                                                                </>
                                                                : (selectedTransaction?.paymentStatus === 'succeeded' ? <span className='p_cat_text' style={{background:'green'}}>Payment successful</span> 

                                                                : '')

                                                }
                                            </div>
                                        }
                                        { selectedTransaction?.transaction_id  && (cancellationType.value === 3 || cancellationType.value === 4) ? 
                                        programRefundAmount ? 
                                            <Fragment>
                                                    <div className='form-group'>
                                                    <label>Enter Refund Amount</label>
                                                        <CustomNumber
                                                            className={`form-control ml-3 ${newRefundAmountErr ? 'is-invalid' : ''}`}
                                                            formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            change={e => (setNewRefundAmount(e), setNewRefundAmountErr(''))}
                                                            value={newRefundAmount}
                                                            min={0}
                                                            precision={2}
                                                            error={newRefundAmountErr}
                                                            pHolder="Enter Refundeble Amount"  />
                                                            {newRefundAmountErr && <span className='text-danger mt-2'>{newRefundAmountErr}</span>}
                                                    </div>
                                            </Fragment> : 
                                            <h5>Fully Refunded</h5>
                                            
                                            :''                           
                                        }
                                        <div className="d-flex justify-content-center mt-5 mb-4">
                                                <button className={`btn btn-primary rounded-0  btn_anim ${btnLoader ? 'show_anim' : ''}`} onClick={btnLoader ? null : handleCancelRefundBookingAction}>PROCEED</button> 
                                                
                                        </div>
                                    </div>
                                </Modal.Body>
            </Modal> 

            <Modal
                show={paymentInfoModal}
                animation={false}
                centered>
                <Modal.Body>
                    <span className="modal_close" onClick={() => setPaymentInfoModal(false)}><i className="icon-cross" /></span>
                    <div className='text-center p-4'>
                    { activeProduct?.paymentArr?.length > 0 ? 
                                    activeProduct?.paymentArr?.map(pArr => (
                                        <div className='p_arr'>
                                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Payment Date :</span> {convertTimeToCurrentTimeZone(pArr.paymentDate, props?.userProfile?.timezone, PaymentDateFormat)}</p>
                                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Transaction Id:</span> {pArr.transaction_id || 'N/A'}</p>
                                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Payment Method:</span> {pArr.method}</p>
                                            <p className='d-flex justify-content-between align-items-center'><span className="color_black">Amount:</span> ${pArr.amount.toFixed(2)}</p>
                                                {
                                                            pArr?.paymentStatus === 'failed' ? 
                                                            <>
                                                            <p style={{ fontSize: "12px" , color:'red'}}><i>{pArr?.failed?.errorDescription}   </i></p>
                                                            <p style={{color:'red'}} className='d-flex justify-content-between align-items-center'> <span className='p_cat_text' style={{background:'red'}}>Payment Failed</span> {
                                                            convertTimeToCurrentTimeZone(pArr?.failed?.paymentDate, props?.userProfile?.timezone, PaymentDateFormat)
                                                            }</p>
                                                           
                                                            </>
                                                            : ''

                                                } 
                                                {      pArr?.refunds?.length > 0  ?
                                                                <>
                                                                <div className='d-flex justify-content-between'>
                                                                  <span className='p_cat_text' style={{background:'blue'}}>Payment Refunded</span>
                                                                </div>
                                                                    {
                                                                        pArr?.refunds?.map( rf =>
                                                                            <>  
                                                                                <div style={{color:'blue'}} className='d-flex justify-content-between align-items-center mb-0'>
                                                                                    <span>Refunded Amount : $ {rf?.amount}</span>
                                                                                    {rf.status === 'pending' && <span className = 'mt-0' style={{fontSize:"10px"}}><i>(Pending)</i></span>}
                                                                                    <span> {convertTimeToCurrentTimeZone(rf?.paymentDate, props?.userProfile?.timezone,PaymentDateFormat)}</span>
                                                                                </div>
                                                                                
                                                                                
                                                                            </>
                                                                    )
                                                                    }
                                                                </>
                                                                : (pArr?.paymentStatus === 'succeeded' ? <span className='p_cat_text' style={{background:'green'}}>Payment successful</span> 

                                                                : '')

                                                }
                                        </div>
                                    ))
                                    :
                                    <h5>Payment under process</h5>
                                }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={answerModal}
                animation={false}
                centered>
                <Modal.Body>
                    <span className="modal_close" onClick={closeActiveQuestionModel}><i className="icon-cross" /></span>
                 { (activeQuestions.length> 0) && 
                    <QuestionsComponent 
                    questions= {activeQuestions}
                    setQuestions={setActiveQues}
                    source={2}
                    /> }
                 <div className='text-center mt-4'>
                                    <span onClick={handleSubmitAns} className='btn btn-primary'>Save</span>
                                </div>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={paymentModal}
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Payments</Modal.Title>
                    <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>


                {
                    activePaymentObject?.isEmi?.allEmiRemains?.length ? (
                        <Fragment>
                            <p><b>Select Amount:</b></p>
                            {
                                activePaymentObject?.isEmi.allEmiRemains.filter(mmi => mmi.status !== 1).map(emi => (
                                    <div onClick={() => (setEmiObj(emi), setAmount(emi.emiAmount))} key={emi._id} className={`d-flex justify-content-between align-items-center emi_chip ${emi._id === emiObj._id ? 'active' : ''}`}>
                                        <div><date>Date: {moment(emi.emiDate).format('MMM DD, YYYY')}</date></div> -
                                        <div>Amount: <b>${emi?.emiAmount?.toFixed(2)}</b></div>
                                    </div>
                                ))
                            }
                        </Fragment>
                    ) : (
                        <Fragment>
                         <h4>Remaining Amount: ${activePaymentObject?.emiAmount?.toFixed(2)}</h4>
                        </Fragment>
                    )
                    }
                    

                    
                   


               

                    <div className={`form-group`}>
                        <label>Enter Amount to Pay</label>
                        <NumericInput
                            step={0.1}
                            precision={2}
                            value={amount}
                            className="form-control"
                            min={ 0 } 
                            max={ 100 } 
                            size={ 2 } 
                            onChange={e => setAmount(e)}
                            pattern="[0-9].[0-9][0-9]" />
                    </div>

                    <h5>Select a Payment Option</h5>

                        <div className="row">

                            {
                                creditCardRate.status === 1 ? (
                                    <div className="col-md-6">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod === 1 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(1), setPaymentMethodErr(''))}>

                                            <h4>Credit Card / Debit Card</h4>
                                            <h2>${getWithTaxPrice(creditCardRate, amount)}</h2>
                                            <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }



                            {
                                achTransferRate.status === 1 ? (
                                    <div className="col-md-6">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod === 3 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(3), setPaymentMethodErr(''))}>

                                            <h4>Internet Banking</h4>
                                            <h2>${getWithTaxPrice(achTransferRate, amount)}</h2>
                                            <p>Payment Includes {achTransferRate.percentage}% + {`$${achTransferRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }


        
                        </div>


                        {paymentMethodErr ? <p className="color_red">{paymentMethodErr}</p> : ''}


                



                        {
                            (paymentMethod === 1) ? (
                                <Fragment>
                                    <ul className="card_list">
                                        {loader ? 
                                            <div className="program_loader"><ProgramLoader /></div>
                                            :
                                              (cardList.length ? cardList.map(item => (
                                                <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''} style={{justifyContent: 'space-between'}}>
                                                 <CreditCard
                                                        item={item}
                                                        customerPaymentProfileId={customerPaymentProfileId}
                                                        setCustomerPaymentProfileId={setCustomerPaymentProfileId}
                                                        setCustomerPaymentProfileIdErr={setCustomerPaymentProfileIdErr}
                                                        handlePrimaryCard={handlePrimaryCard}
                                                        handleDeleteCard={handleDeleteCard}
                                                    />
                                                </li>

                                            )) : '')
                                        }
                                    </ul>

                                    {customerPaymentProfileIdError ? <p className="color_red text-center">{customerPaymentProfileIdError}</p> : ''}


                                {
                                    isNewCard ? (
                                    <AddNewCard
                                        setIsNewCard={setIsNewCard}
                                        email={userEmail}
                                        userId={userId}
                                        getCards={getCards}
                                        setResErr={setResErr}
                                        campCode={props.campCode}
                                  />
                                    ) : <button className="btn btn-primary mb-3" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                } 
                            </Fragment>
                            ) : ''
                        }





{    
                            paymentMethod === 3 ? (
                                <Fragment>
                                    <div className="card_inputs add_card_form">

                                        <div className="form-group">
                                            <label>Select Account Type</label>
                                            <Select
                                                className={`form-control ${bankAccountTypeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Account Type"
                                                onChange={e => (setBankAccountType(e), setBankAccountTypeErr(''))}>

                                                <Option value="checking">Checking</Option>
                                                <Option value="savings">Savings</Option>
                                                <Option value="businessChecking">business Checking</Option>

                                            </Select>

                                            {bankAccountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        <div className="form-group">
                                            <label>Routing Number</label>
                                            <input
                                                type='number'
                                                className={`form-control ${routingNumberErr ? 'is-invalid' : ''}`}
                                                value={routingNumber}
                                                placeholder="Routing Number"
                                                name="routingNumber"
                                                onChange={e => (setRoutingNumber(e.target.value), setRoutingNumberErr(''))} />

                                            {routingNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routingNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>

                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <SecureInput
                                                type='number'
                                                error={bankAccountNumErr}
                                                value={bankAccountNum}
                                                name={"bankAccountNum"}
                                                placeholder={"Account Number"}
                                                maxLength={30}
                                                onChange={handleBankAccountNumberChange}
                                            />

                                            {bankAccountNumErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountNumErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>





                                        <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${nameOnAccountErr ? 'is-invalid' : ''}`}
                                                name="nameOnAccount"
                                                value={nameOnAccount}
                                                onChange={e => (setNameOnAccount(e.target.value), setNameOnAccountErr(''))}
                                                placeholder="Account Holder Name" />

                                            {nameOnAccountErr? <OverlayTrigger placement="left" overlay={<Tooltip>{nameOnAccountErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        </div>   
                            </Fragment>
                            ) : ''
                        }

                        {errorMsg ? <p className="color_red">{errorMsg}</p> : ''}
                            <div className="d-flex justify-content-between mt-5">
                                <span onClick={handleCloseModal} className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                                <span onClick={() => btnLoader ? null : handleCreateBooking()} className={`btn btn-primary rounded-0 w-100  ${btnLoader ? 'btnLoader' : ''}`}>Pay</span>
                            </div>

                </Modal.Body>
            </Modal>

            <Modal
                dialogClassName="h_auto"
                show={isViewAddProducts}
                animation={false}
                size="lg"
                backdrop="static"
                centered>
                <Modal.Body>
               { 
               loading ? 
               <div className="program_loader"><ProgramLoader /></div> :                  
                    <div className="p-2 py-5 product_moda input_err_pos">
                        <span className="modal_close" onClick={handleAddCloseModal}><i className="icon-cross" /></span>

                        <div className='row'>
                            <div className='col-lg-5'>
                            {
                                activeAddProduct?.image?.length ? <Slider {...settings}>
                                                        {activeAddProduct?.image.map(img => (
                                                                <img src={img} className="img-fluid" alt=""/>
                                                        ))}
                                                    </Slider> : ''
                            }

        
                            </div>
                            <div className='col-lg-7'>
                                <h3 style={{color: activeAddProduct.textColor || '#000'}}>{activeAddProduct.title}</h3>

                                
                                 <Fragment>


                                    <div className='d-flex justify-content-between align-items-center'>
                                        {getSizes(activeAddProduct.sizes)?.length ? <div  className="form-group w-100 mr-2">
                                            <label>Size</label>
                                            <Select
                                                className={`form-control ${selectedSizeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Size"
                                                value={selectedSize}
                                                onChange={e => (setSelectedSize(e), setSelectedSizeErr(''), setSelectedColor(''))}
                                                classNamePrefix="cm_select"
                                                options={getSizes(activeAddProduct.sizes)} />
                                             

                                            {selectedSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div> : ''}

                                        {
                                            getColors2(activeAddProduct.sizes, selectedSize.value)?.length ? <div className={`form-group w-100 ${getSizes(activeAddProduct.sizes)?.length ? 'ml-2' : ''}`}>
                                            <label>Color</label>
                                            <Select
                                                isDisabled={(getSizes(activeAddProduct.sizes).length && !selectedSize.value) ? true : false}
                                                className={`form-control ${selectedColorErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Color"
                                                value={selectedColor}
                                                onChange={e => (setSelectedColor(e), setSelectedColorErr(''))}
                                                classNamePrefix="cm_select"
                                                // options={getColors(activeProduct.sizes)?.length ? getColors(activeProduct.sizes) : []} />
                                                options={getColors2(activeAddProduct.sizes, selectedSize.value)} />

                                            {selectedColorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedColorErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        
                                        </div> : ''
                                        }                                       

                                        
                                    </div>


                                    <div className="quantity mb-4 mt-0">
                                        <button onClick={() => handleAddToCartCountChange('remove')} className="inc"></button>
                                        <b>{currentCount}</b>
                                        <button onClick={() => handleAddToCartCountChange('add')} className="dec"></button>
                                    </div>

                                </Fragment>

                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    

                                    <div className="product_price">
                                        <h2><b>Item Price: ${activeAddProduct?.price?.toFixed(2)}</b></h2>
                                    </div>
                                    <div className="product_price" >
                                        <h2><b>Total Price: ${calculateTotalProductPrice().toFixed(2)}</b></h2>
                                </div>

                                    
                                </div>
                                {existingWarning && <span className="p_cat_text  p-2">{existingWarning}</span>}
                                <div className=''>
                                    {getHtml(activeAddProduct.desc)}
                                </div>
                                
                                <div className='mt-4 d-flex justify-content-end'>
                                  {   isProductInCart(activeAddProduct._id)? (
                                    <>
                                    <button onClick={() => handleRemoveToCartProduct(activeAddProduct, -1)} className="btn btn-danger btn-sm">REMOVE</button>
                                    <button onClick={() => updateCartItemsProducts(activeAddProduct)} className="btn btn-primary btn-sm ml-3" >UPDATE</button>
                                    </> ):
                                  <button className="btn btn-primary btn-sm" onClick={() => handleAddToCartProduct(activeAddProduct)}>ADD TO CART</button>
                                  }
                                </div>
                                    
                                </div>
                                {
                                    (activeAddProduct && activeAddProduct?.subItems?.length > 0) &&  
                                    <div className='col-sm mt-3'>
                                    <h4>Add-ons</h4>
                                    <div className ="container">
                                        <div className="row">
                                            {       
                                            activeAddProduct?.subItems?.map(item => 
                                                (<Addon 
                                                    key={item._id} 
                                                    item={item} 
                                                    updateAddons={addAddonsToList}
                                                    count = {item?.count}
                                                    sizes= {item?.selectedSize}
                                                    colors = {item?.selectedColor}
                                                    />))
                                            }
                                        </div>
                                    </div>
                                    
                                    </div>
                                }
                            </div>
                       
                    </div>}
                </Modal.Body>
            </Modal>

            <Modal
                dialogClassName="h_auto"
                show={campRelatedProductsModal}
                animation={false}
                size='xl'
                centered>
                    <Modal.Header>
                        <Modal.Title>Product List</Modal.Title>
                        {passDataArr.length ? <span onClick={handleCheckout} className='btn btn-primary cm_Checkout_btn'>Checkout</span> : ''}
                        
                        <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
                    </Modal.Header>
                <Modal.Body>
                    <div className="p-2 d_prodcut_list">
                        {//Here make changes to 
                            campRelatedProducts.map((item, itemIndex) => (
                                <div className='row camp_related_product' key={item._id}>
                                    <div className='col-lg-1' />
                                    <div className='col-lg-3'>
                                    {
                                        item?.image?.length ? <Slider {...settings}>
                                                                {item?.image.map(img => (
                                                                        <img src={img} className="img-fluid"  key={uuidv4()}/>
                                                                ))}
                                                            </Slider> : ''
                                    }

                
                                    </div>
                                    <div className='col-lg-1' />
                                    <div className='col-lg-7'>
                                
                                        <h3 style={{color: item.textColor || '#000'}}>{item.title}</h3>
                                            <div className="mb-3">
                                                <b>Count : {item.count}</b>  
                                            </div>
                                        { passDataArr.find(p => p.itemId === item._id)?.subItems?.length > 0
                                        &&
                                        isAllSubitemsCountNonZero(item._id)
                                        && 
                                            <>
                                                <h4 style={{color: item.textColor || '#000'}}>Add-ons</h4>
                                                {
                                                    item.subItems.map(subItem => 
                                                    subItem?.count > 0 && 
                                                        <div className="mb-1">
                                                            <span className='mr-5'>{subItem.title}  </span>   
                                                            <b>Count : {subItem.count}</b>  
                                                        </div>                                                   
                                                )
                                                }
                                            </>
                                        }


                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            
                                            <div className="">
                                                {
                                                    isProductInCart(item._id)? (
                                                        <button onClick={() => handleRemoveToCartProduct(item, itemIndex)} className="btn btn-danger btn-sm">REMOVE</button>
                                                    ) : (
                                                        <button onClick={() => handleShowModalProductDetail(item, itemIndex)} className="btn btn-primary btn-sm">ADD</button>
                                                    )
                                                }
                                            
                                            </div>

                                            <div className="product_price">
                                                <h2><b>Price: ${((item?.price * item?.count) + getSubItemPrice(item?._id)).toFixed(2)}</b></h2>
                                            </div>
                                        </div>

                                        {/* <div className=''>
                                            {getHtml(item.desc)}
                                        </div> */}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                dialogClassName="h_auto"
                show={productCheckoutModal}
                animation={false}
                size='lg'
                centered
            >
                <Modal.Body>
                    <span className="modal_close" onClick={() => setProductCheckoutModal(false)}><i className="icon-cross" /></span>
                    {productCheckoutModal ? <ProductPaymentGateway passData={passDataArr} userId={userId} userEmail={userEmail} callback={handleBookingSubmit} /> : ''}
                    
                </Modal.Body>
            </Modal>

                <Modal
                    dialogClassName="h_auto"
                    show={insuranceModal}
                    animation={false}
                    size='xl'
                    centered
                >
                <Modal.Body>
                    <span className="modal_close" onClick={() => setInsuranceModal(false)}><i className="icon-cross" /></span>
                    <div className='p-5'>
                    {insuranceModal && ( 
                         <AddInsurance 
                            couponMoney={allData?.couponValue}
                            allData={allData} 
                            userEmail={userEmail} 
                            programId={programId} 
                            userId={userId}
                            programDetails={programDetails}
                            addedinsurance={addedinsurance}
                            additionalExperience={additionalExperience}
                            callBack= {InsurancePurchaseCallback}
                            camperId={camperId}
                            setInsuranceModal={setInsuranceModal}
                            showInsurance={showInsurance}
                        />
                    )}
                    </div>
                    
                </Modal.Body>
            </Modal>

            <Modal
                show={confirmCancelProductModal}
                animation={false}
                centered>
                <Modal.Body>
                    <span className="modal_close" onClick={handleProductActionModal}><i className="icon-cross" /></span>
                    <div className='text-center p-4'>
                    {(productCancellationType.value === 3 || productCancellationType.value === 4) &&  <h4>Refundable amount: ${calculateProductRefundPrice(activeProductData)?.toFixed(2)}</h4>}
                        <label>Select Action Type</label>
                        <div className="form-group">
                            <ReactSelect
                            className="form-control"
                            placeholder="Select Action Type"
                            classNamePrefix="cm_select"
                            value={productCancellationType}
                            onChange={e => (setProductCancellationType(e))}
                            options={cancellationOptions} />
                        </div>

                        {(productCancellationType.value === 3 || productCancellationType.value === 4) ? 
                        <Fragment>
                                <div className='form-group'>
                                <label>Enter Refund Amount</label>
                                    <NumericInput
                                        className="form-control"
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        onChange={(e) => handleProductRefundAmount(e)}
                                        value={productRefundAmount}
                                        min={0}
                                        precision={2}
                                        error={productRefundAmountErr}
                                        />
                                        {productRefundAmountErr && <span className='text-danger mt-2'>{productRefundAmountErr}</span>}
                                </div>
                        </Fragment> :''
                                                                    
                        }
                        <div className="d-flex justify-content-center mt-5 mb-4">
                                <button className={`btn btn-primary rounded-0  btn_anim ${btnLoader ? 'show_anim' : ''}`} onClick={btnLoader ? null : handleProductCancelRefundAction}>PROCEED</button> 
                                
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={activeForm.camperId}
                centered
                size='xl'
                dialogClassName="remove_header_line">

                <Modal.Header>
                <span className="modal_close" onClick={() => setActiveForm({})}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <div className='embed_div'>
                         <PdfViewer fileUrl={activeForm.url}/>
                    </div>

                    

                
                    <div className="d-flex mt-3">
                        <button onClick={() => setActiveForm({})} className="btn btn-dark w-100">Cancel</button>
                        {
                            activeForm.viewType ? '' : <button onClick={updateBookingKey} className="btn btn-primary w-100 ml-3">I Agree</button>
                        }
                        
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                            dialogClassName="h_auto"
                            show={amountUpdateModal}
                            animation={false}
                            centered
                        >
                             <Modal.Header>
                                <Modal.Title>Update Amount</Modal.Title>
                                <span className="modal_close" onClick={() => (setAmountUpdateModal(false), setEmiId(''), setNote(''))}><i className="icon-cross" /></span>
                            </Modal.Header>
                        <Modal.Body>
                            <div className="p-2">
                                <div className={`form-group`}>
                                    <label>Amount</label>
                                    <CustomNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => setRemainingAmount(e)}
                                        value={remainingAmount}
                                        min={0}
                                        precision={remainingAmount ? 2 : 0}
                                        pHolder={`Amount`}
                                    />
                                </div>


                                <div className={`form-group`}>
                                    <label>Note</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        value={note}
                                        onChange={e => (setNote(e.target.value), setNoteErr(''))}
                                    />

                                    {noteErr ? <h6 className='cm_err'>{noteErr}</h6> : ''}
                                </div>
                                

                                <span onClick={() => remainingAmountLoader ? null : handleUpdateAmount()} className='btn btn-primary'>Update</span>
                            </div>
                        </Modal.Body>
            </Modal>

            <Modal
                dialogClassName="h_auto"
                show={updateEMIDateModal}
                animation={false}
                centered
            >
                    <Modal.Header>
                    <Modal.Title>Update Date</Modal.Title>
                    <span className="modal_close" onClick={() => (setUpdateEMIDateModal(false), setActiveEMI(''))}><i className="icon-cross" /></span>
                </Modal.Header>
            <Modal.Body>
                <div className="p-2">

                    <div className={`form-group`}>
                        <label>Select Date</label>
                        <DatePicker
                            disabledDate={disabledDate}
                            value={selectEmiDate || moment.utc(parseInt(activeEMI.emiDate))}
                            className="form-control"
                            placeholder="Select Date"
                            format="MM/DD/YYYY"
                            onChange={e => setSelectEmiDate(e)} />
                    </div>
                    

                    <span onClick={handleEMIDateChange} className='btn btn-primary'>Update</span>
                </div>
            </Modal.Body>
            </Modal>

            <Modal
                    show={isDeletePopup || isSwitchPrimaryCard}
                    animation={false}
                    dialogClassName="folder_model"
                    size="lg"
                    centered
                        >
                        {isSwitchPrimaryCard && 
                            <Modal.Body>
                            <span className="modal_close" onClick={() => { setIsSwitchPrimaryCard(false) }}><i className="icon-cross" /></span>
                            <div className="text-center p-5 pickup_modal_body">
                                <p>Are you sure you want to change your primary card ?</p>
                            </div>
                            <div className="text-center p-5">
                                <button onClick={() => { setPrimaryCardId(''); setIsSwitchPrimaryCard(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                                <button onClick={handlePrimaryCardAction} style={{ width: '120px' }} className="btn btn-primary ml-3">Yes</button>
                            </div>
                            </Modal.Body>
                        
                        }
                        { isDeletePopup && 
                        <Modal.Body>
                        <span className="modal_close" onClick={() => { setIsDeletePopup(false) }}><i className="icon-cross" /></span>
                        <div className="text-center p-5 pickup_modal_body">
                            <p>Are you sure you want to remove this card ?</p>
                        </div>
                        <div className="text-center p-5">
                            <button onClick={() => { setDeleteCardToken(''); setIsDeletePopup(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                            <button onClick={deleteCardAction} style={{ width: '120px' }} className="btn btn-primary ml-3">Yes</button>
                        </div>
                        </Modal.Body>
                
                        }
            </Modal>

            <Modal
                dialogClassName="h_auto"
                show={isViewProducts}
                animation={false}
                onHide={handleCloseProductModal}
                centered>
                <Modal.Body>
                    <span className="modal_close" onClick={handleCloseProductModal}><i className="icon-cross" /></span>
                <ShowProductInfo activeProduct={activeProduct}/>
            </Modal.Body>
            </Modal>
            <ChargeCamperModal
                modalState={modalState}
                chargeModal={chargeModal}
                handleCloseChargeModal={handleCloseChargeModal}
                handleCamperChange={handleCamperChange}
                handleChargeAmountReason={handleChargeAmountReason}
                handleChargeAmountChange={handleChargeAmountChange}
                userId={userId}
            />

        </div>
    )
}


const mapStateToProps = state => {
    let { preList } = state.program;
    let { saveCabinId } = state.ui;
    let { category, subcategory } = preList;
    let { userProfile } = state.user;
    let { campCode } = userProfile;
    
    return {
        category,
        saveCabinId,
        campCode,
        subcategories: subcategory,
        userProfile,
    };
  }
  

const mapDispatchToProps = dispatch => ({
    saveCabinIdAction: params => dispatch(saveCabinIdAction(params)),
});
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(CamperDetail);