import React, { useState, useRef, useCallback, useEffect, Fragment } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { Empty, InputNumber, notification } from 'antd';
import { shortsAndTshirtSizes, colorsList, getCroppedImg, getHtml, isNonEmptyArray } from '../../helper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';
import { getAllPrgramWithId, getCartProductsService, userBookingListForProductsService } from '../../services/programService';
import { createProductService, getProductsService, editProductService, addProductIntoCartService, getProductsCartCountService, deleteProductService, itemCategoryAddService, getItemCategoryService, getUserBookedCampsService, deleteProductCatService } from '../../services/onlineStoreService';
import { uploadMedia } from '../../services/campersServices';
import { ProgramLoader } from '../reuse/SVG';
import Slider from 'react-slick';
import { productCartCountAction } from '../../store/cart';
import Addon from './Addon';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};


export const getSizes = arr => {
    let filterArr = [];
    if(arr?.length){
        let isHasSize = arr.filter(i => (i.name != 'No Size'));
        if(isHasSize.length){
            let onlyName = isHasSize.map(item => item.name);
            let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
            filterArr = uniqArr.map(i => ({value: i, label: i}))
        }
    }
    return filterArr;
}

export const getColors = arr => {
    let filterArr = [];
    if(arr?.length){
        filterArr = arr.filter(item => item.color).map(i => ({value: i.color, label: i.color}))
    }
    return filterArr;
}




export const getColors2 = (arr, val) => {
    let filterArr = [];

    if(val){
        if(arr?.length){
            let isHasColor = arr.filter(i => i.name == val);
            let isHasColor2 = isHasColor.filter(i => i.color != 'No Color');
            if(isHasColor2.length){
                let onlyName = isHasColor2.map(item => item.color);
                let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({value: i, label: i}))
            }
        }
    }else{
        if(arr?.length){
            let isHasColor = arr.filter(i => i.color != 'No Color');
            if(isHasColor.length){
                let onlyName = isHasColor.map(item => item.color);
                let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({value: i, label: i}))
            }
        }
    }
    return filterArr;
}


const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Product not Available at Current Registered Program locations. Please register for new program to complete product purchase.</span> 
      </components.NoOptionsMessage>
    );
  };

const OnlineStore = (props) => {

    const [pageLoader, setPageLoader] = useState(true);
    const [allPrograms, setAllPrograms] = useState([]);
    const [products, setProducts] = useState([]);
    const [subProducts, setSubProducts] = useState([]);
    const [subProductsIds, setSubproductsIds] = useState([]);
    const [count, setCount] = useState(0);
    const [isViewProducts, setIsViewProducts] = useState(false);
    const [activeProduct, setActiveProduct] = useState('');
    const [addProductModal, setAddProductModal] = useState(false);
    const [tshirtSize, setTshirtSize] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [isAll, setIsAll] = useState(0);

    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedColorErr, setSelectedColorErr] = useState('');
    const [selectedSizeErr, setSelectedSizeErr] = useState('');
    const [hasSize, setHasSize] = useState(true);
    const [hasColor, setHasColor] = useState(true);
  


    const [title, setTitle] = useState('');
    const [skuNumber, setSkuNumber] = useState('');
    const [price, setPrice] = useState('');
    const [textColor, setTextColor] = useState('');
    const [sizes, setSizes] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalCountErr, setTotalCountErr] = useState(0);
    const [desc, setDesc] = useState('');
    const [programIds, setProgramIds] = useState([]);
    const [registrationDisplay, setRegistrationDisplay] = useState(0);
    const [storeDisplay, setStoreDisplay] = useState(1);
    const [currentCount, setCurrentCount] = useState(1);
    const [totalPriceOfActiveProduct, setTotalPriceOfActiveProduct] = useState(0);

    const [titleErr, setTitleErr] = useState('');
    const [skuNumberErr, setSkuNumberErr] = useState('');
    const [priceErr, setPriceErr] = useState('');
    const [textColorErr, setTextColorErr] = useState('');
    const [leftItemErr, setLeftItemErr] = useState('');
    const [categoryModal, setCategoryModal] = useState(false);
    const [category, setCategory] = useState('');
    const [categoryErr, setCategoryErr] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [catLoader, setCatLoader] = useState(false);
    const [catList, setCatList] = useState([]);
    const [selectedCat, setSelectedCat] = useState('');
    const [selectedSubCat, setSelectedSubCat] = useState('');
    const [f_cat, setF_cat] = useState('');
    const [f_sub_cat, setF_sub_cat] = useState('');
    const [selectedCamp, setSelectedCamp] = useState('');
    const [bookedCampsList, setBookedCampsList] = useState([]);
    const [bookingId, setBookingId] = useState('');
    const [bookingIdErr, setBookingIdErr] = useState('');
    const [bookingList, setBookingList] = useState([]);
    const [fBookingList, setFBookingList] = useState([]);
    const [addons, setAddons] = useState([]);


    const [images, setImages] = useState([]);
    const [imagesErr, setImagesErr] = useState([]);
    const [cImage, setCImage] = useState('');
    const [upImg, setUpImg] = useState();
    const [uploadImage, setUploadImage] = useState('');
    const [loader, setLoader] = useState(false);
    const imgRef = useRef(null);
    const mediaRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 1 / 1 });


    const limit = 12;


    useEffect(() => {
        let query = ``;
        userBookingListForProductsService(query).then(res => {
            if (res.data.statusCode) {
                setBookingList(res.data.responseData.result);
            }
        })
    }, [])




    const getItemCategory = () => {
        let query = ``;
        if(props?.userProfile?.UserType == 2 || props?.userProfile?.UserType == 3 || props?.userProfile?.UserType == 4){
            query = `?limit=1000&page=1&campCode=${props?.userProfile?.campCode}`
        }else{
            query = `?limit=1000&page=1`
        }
        getItemCategoryService(query).then(res => {
            if(res?.data?.statusCode){
                setCatList(res.data.responseData.result);
            }
        })
    }


    const handleProductCatDelete = categoryId => {
        let params = { categoryId }
        deleteProductCatService(params).then(res => {
            if(res?.data?.statusCode){
                notification.open({
                    description:
                        'Category Deleted Successfully',
                        placement: 'topLeft'
    
                });
                getItemCategory();
            }
            
        })
    }


    const getUserBookedCamps = () => {
        let query = ``;

        getUserBookedCampsService(query).then(res => {
            if(res?.data?.statusCode){
                setBookedCampsList(res.data.responseData.result);
            }
        })
    }

    useEffect(() => {
        getItemCategory();
        getUserBookedCamps();
    }, []);


    const handleSubCatAdd = () => {
        let _subCategories = [...subCategories];
        _subCategories.push('');
        setSubCategories(_subCategories);
    }


    const handleCatChange = (e, i) => {
        let val = e.target.value || '';
        let _subCategories = [...subCategories];
        _subCategories[i] = val;
        setSubCategories(_subCategories);
    }


    const handleDeleteCat = i => {
        let _subCategories = [...subCategories];
        _subCategories.splice(i, 1);
        setSubCategories(_subCategories);
    }


    const handleProductEditModal = (_activeProduct) => {
        setIsViewProducts(false);
        setAddProductModal(true);



     
        setIsAll(_activeProduct.isAll)
        setTitle(_activeProduct.title);
        setSkuNumber(_activeProduct.skuNumber);
        setPrice(_activeProduct.price);
        setTotalCount(_activeProduct.totalCount);
        setDesc(_activeProduct.desc);
        setRegistrationDisplay(_activeProduct.registrationDisplay);
        setStoreDisplay(_activeProduct.storeDisplay);
        if(_activeProduct?.image?.length){
            setImages(_activeProduct.image);
        }

        
        setSizes(_activeProduct.sizes);

        let _hasSize = _activeProduct.sizes.filter(i => i.name);
        if(_hasSize.length){

        }else{
            setHasSize(false)
        }

    
 
        if(_activeProduct.textColor){
            setTextColor({label: _activeProduct.textColor, value: _activeProduct.textColor});
        }

        if(_activeProduct?.colorOptions?.length){
            let _colorOptions = [];

            for (let i = 0; i < colorsList.length; i++) {
                if(_activeProduct.colorOptions.includes(colorsList[i])){
                    _colorOptions.push({
                        value: 1,
                        name: colorsList[i],
                    })
                }else{
                    _colorOptions.push({
                        value: 0,
                        name: colorsList[i],
                    })
                } 
            }

            setColorOptions(_colorOptions)
        }



        if(_activeProduct.isAll == 1){
            let _programIds2 = allPrograms.map(item => ({label: item.programName, value: item._id}))
            setProgramIds(_programIds2);
        }else {
            let _programIds2 = allPrograms.filter(item => _activeProduct.programId.find(itm => itm == item._id)).map(itme => ({label: itme.programName, value: itme._id}))
            setProgramIds(_programIds2);
        }

        if(_activeProduct.categoryName){
            if(catList.length){
                let _category = catList.find(i => i.name == _activeProduct.categoryName);
                if(_category.name){
                    setSelectedCat({..._category, label: _category.name, value: _category._id})
                }
            }
        }

        if(_activeProduct?.subCategory?.length){
            setSelectedSubCat({..._activeProduct.subCategory[0], label: _activeProduct.subCategory[0].name, value: _activeProduct.subCategory[0]._id})
        }


    }


    useEffect(() => {
        if(props?.userProfile?.campCode){
            getAllPrgramWithId(`?campCode=${props.userProfile.campCode}`).then(res => {
                if(res?.data?.statusCode){
                    setAllPrograms(res.data.responseData.result);
                }
            })
        }
       
    },[props.userProfile])

    const handleCloseModal = () => {
        setBookingId('')
        setBookingIdErr('')
        setIsViewProducts(false);
        setHasSize(true);
        setHasColor(true);
        setActiveProduct('');
        setAddProductModal(false);
        setTitle('');
        setSkuNumber('');
        setTextColor('');
        setPrice('');
        setProgramIds([]);
        setTotalCount(0);
        setTotalCountErr('');
        setRegistrationDisplay(0);
        setSelectedColor('')
        setSelectedSize('')
        setStoreDisplay(1);
        setTitleErr('');
        setSkuNumberErr('');
        setPriceErr('');
        setImages([]);
        setImagesErr([]);
        setTextColorErr('');
        setDesc('');
        setCurrentCount(1);
        setLeftItemErr('');
        setCategoryModal(false);
        setCategory('');
        setCategoryErr('');
        setSubCategories([]);
        setSelectedSubCat('');
        setSelectedCat('');
        setSubproductsIds([]);
        setAddons([]);

        let _colorOptions = colorsList.map((item, i) => ({
            value: 0,
            name: item,
        }))
        setColorOptions(_colorOptions);

        let _sizes = shortsAndTshirtSizes.map((item, i) => ({
            value: 0,
            name: item,
        }))
        setSizes([]);
    }



   



    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
          setCImage(e.target.files)
          const reader = new FileReader();
          reader.addEventListener("load", () => setUpImg(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        }
    };
    
    
    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);



    const completedCrop = crop => {
        getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => setUploadImage(res))
    }


    const handleFileUpload = () => {
        setImagesErr('');
        if(upImg){
          setLoader(true);
                const data = new FormData();
                data.append('file', uploadImage);
    
                uploadMedia(data).then((res) => {
                    if(res.data.responseData.result.fileUrl !== undefined) {
                        setLoader('');
                        setCImage('');
                        setUpImg('');
                        let _images = [...images];
                        _images.push(res.data.responseData.result.fileUrl)
                        setImages(_images);
                    }
                    
                })
   
        }
    }


    const handleRemove = (index) => {
        let _images = [...images];
        _images.splice(index, 1);
        setImages(_images);
    }



    const handleValidate = () => {
        let validate = true;

        if(title == ''){
            setTitleErr('Title is required');
            validate = false
        }

        if(price == ''){
            setPriceErr('Price is required');
            validate = false
        }

        if(skuNumber == ''){
            setSkuNumberErr('SKU number is required');
            validate = false
        }

        if(images.length == 0){
            setImagesErr('Product image is required');
            validate = false
        }
        

        return validate

    }





    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){

            setBtnLoader(true);

            let _sizes = sizes.map(i => ({
                ...i,
                color: i.color || 'No Color',
                name: i.name || 'No Size',
                value: i.value || 0,
            }))
            const _subProducts = subProductsIds.map(item => item.value);

            let params = {
                title,
                skuNumber,
                category: selectedCat.value,
                subCategory: JSON.stringify([selectedSubCat.value]),
                textColor: textColor.value,
                price,
                registrationDisplay,
                storeDisplay,
                sizes: _sizes,
                totalCount,
                desc,
                image: images,
                campCode: props.userProfile.campCode,
                isAll,
                subItemIds:[..._subProducts],
            }

            if(programIds.length){
                let _programIds = programIds.map(i => i.value);
                params.programId = _programIds
            }else{
                if(!activeProduct._id){
                    params.isAll = 1;
                } 
                params.programId = [];
            }

            if(activeProduct._id){
                params.itemId = activeProduct._id
            }

            let _service = activeProduct._id ? editProductService : createProductService;

            _service(params).then(res => {
                setBtnLoader(false);
                if(res?.data?.statusCode){
                    handleCloseModal();
                    getProducts();
                }
               
            })
        }
    }



    const getProducts = () => {
        let query = '';
        if(props?.userProfile?.UserType === 1){
            query = `?page=${page - 1}&limit=${limit}&search=${search}&type=1&itemType=1&category=${f_cat || ''}&subCategory=${f_sub_cat || ''}&filterCampId=${selectedCamp || ''}`;
        }else{
            query = `?campCode=${props.userProfile.campCode}&page=${page - 1}&limit=${limit}&search=${search}&category=${f_cat || ''}&subCategory=${f_sub_cat || ''}&filterCampId=${selectedCamp || ''}`;
        }
   
        getProductsService(query).then(res => {
            setPageLoader(false);
            if(res?.data?.statusCode){
                if((props?.userProfile?.UserType) === 1 ){
                    let query = `?type=2`;
                    getCartProductsService(query).then(response => {
                        if(response?.data?.statusCode){
                            if(response.data.responseData.result.length){
                                const _updatedProducts = res.data.responseData.result.map(product => { 
                                    let inCart = false;
                                    response.data.responseData.result.forEach( cartItem => {
                                        if(cartItem.itemId === product._id){
                                            inCart=  true;
                                        } 
                                    })
                                    return {...product, inCart}
                                })
                                setProducts( _updatedProducts);
                            }else{
                                setProducts(res.data.responseData.result);  
                            }
                        }
                    })
                }else{
                setProducts(res.data.responseData.result);
                }
                setCount(res.data.responseData.count);
            }
        })
    }
    const getSubItemsDropDown = () => {
        let query = '';
        query = `?campCode=${props.userProfile.campCode}&category=${f_cat || ''}&subCategory=${f_sub_cat || ''}&filterCampId=${selectedCamp || ''}`;
        
   
        getProductsService(query).then(res => {
            setPageLoader(false);
            if(res?.data?.statusCode){
                if((props?.userProfile?.UserType) === 1 ){
                    setSubProducts(res.data.responseData.result.filter(item => item?.type !== 2 ));
                }else{
                setSubProducts(res.data.responseData.result);
                }
            }
        })
    }

 
    useEffect(() => {

        if(activeProduct?.subItems?.length){
            setSubproductsIds(activeProduct.subItems.map(item => ({label: item.title, value: item.id})))
        }

        activeProduct && setSubProducts(subProducts.filter(item => (item._id !== activeProduct.id))); 
    }, [activeProduct])

    useEffect(() => {
        if(props?.userProfile?.UserType === 1){
            getProducts()
        }else if(props?.userProfile?.campCode){
            getProducts()
            getSubItemsDropDown();
        }
    }, [props?.userProfile?.campCode, search, page, f_cat, f_sub_cat, selectedCamp])
    useEffect(() => {
        setPage(1);
    }, [search, f_cat, f_sub_cat, selectedCamp])

    useEffect(() => {
        if(activeProduct?.subItems?.length){
            isNonEmptyArray(activeProduct?.subItems) &&  setAddons(activeProduct?.subItems.map(item => {
                const _newAddon = {
                    image: item.image,
                    count:0,
                    price:item?.price,
                    title:item?.title,
                    itemId:item.id,
                }
             return _newAddon;
            }))
        }
    }, [activeProduct])



    const handleSelectPrograms = e => {
        if(e && e.length){
            let isAll = e.filter(i => i.value == 'all');
            if(isAll.length){  
                setIsAll(1);
                let _allPrograms = allPrograms.map(item => ({label: item.programName, value: item._id}))
                setProgramIds(_allPrograms);
            }else{
                setIsAll(0);
                setProgramIds(e)
            }
        }else{
            setIsAll(0);
            setProgramIds([])
        }
       
    }
    const handleSelectSubproducts = e => {
        if(e && e.length){
                setSubproductsIds(e)
        }else{
            setSubproductsIds([])
        }  
    }


    const handleAddToCartProduct = (item) => {

            let validate = true;
            let availableCount = 0;


            if(bookingId == '' || bookingId == 'undefined' || bookingId == null){
                validate = false;
                setBookingIdErr('Please select program and camper');
            }
            
            if(getSizes(activeProduct.sizes).length){
                if(selectedSize == '' || selectedSize.value == '' || selectedSize.value == 'undefined' || selectedSize.value == null){
                    setSelectedSizeErr('Please select size');
                    validate = false;
                }

                availableCount = activeProduct.sizes.find(i => i.name == selectedSize.value).value;
                
            }

            if(getColors2(activeProduct.sizes).length){
                if(selectedColor == '' || selectedColor.value == '' || selectedColor.value == 'undefined' || selectedColor.value == null){
                    setSelectedColorErr('Please select color');
                    validate = false;
                }

                availableCount = activeProduct.sizes.find(i => i.color == selectedColor.value).value;


            }


            if(!getColors2(activeProduct.sizes).length && !getSizes(activeProduct.sizes).length){
                availableCount = activeProduct.sizes[0].value;
            }


            if(availableCount < currentCount){
                validate = false;

                if(availableCount == 0){
                    setLeftItemErr(`No more available items in this size or color`); 
                }else{
                    setLeftItemErr(`Only ${availableCount} items are available`);
                }
            }
            if(validate){
                    if(addons.length > 0){
                        
                            let params = {
                                itemId: item._id,
                                color: selectedColor?.value,
                                sizes: selectedSize?.value,
                                count: currentCount,
                                title: activeProduct?.title,
                                price: activeProduct?.price, 
                                bookingId: bookingId?.value,
                                camperId: bookingId.camperId,
                                programId: bookingId.programId,
                                subItems:[...addons], 
                                
                            }
                                addProductIntoCartService(params).then(res => {
                                    if(res.data.statusCode){
                                        setCurrentCount(1);
                                        notification.open({
                                            description:
                                                'Product Added Successfully',
                                                placement: 'top',
                                                style:{
                                                    fontWeight: 'bold',
                                                }
    
                                        });
                                        getProductsCartCount();
                                        getProducts();
                                        
                                }})

                            handleCloseModal();
                            // Promise.allSettled(subProductApis.map(func => func()))
                    }else{
                    
                        if(validate){
                        let params = {
                            itemId: item._id,
                            color: selectedColor.value,
                            sizes: selectedSize.value,
                            count: currentCount,
                            title: activeProduct?.title,
                            price: activeProduct?.price, 
                            bookingId: bookingId.value,
                            camperId: bookingId.camperId,
                            programId: bookingId.programId,
                            subItems:[],
                        }

                        handleCloseModal();
                        addProductIntoCartService(params).then(res => {
                            if(res.data.statusCode){
                                setCurrentCount(1);
                                notification.open({
                                    description:
                                        'Product Added Successfully',
                                        placement: 'top',
                                        style:{
                                            fontWeight: 'bold',
                                        }
                                });
                                getProductsCartCount();
                                getProducts();
                                
                            }})
                    }
                    }
            }
        }


    const getProductsCartCount = () => {
        getProductsCartCountService().then(res => {
            if(res?.data?.statusCode){
                props.productCartCountAction(res.data.responseData.result.itemCount)
            }
           
        })
    }



    const handleDeleteProduct = () => {

        deleteProductService(`/${activeProduct._id}`).then(res => {
            handleCloseModal();
            if(res.data.statusCode){
                notification.open({
                    description:
                        'Product Deleted Successfully',
                }); 
                getProducts();
            }
        })
    }



    const handleCountChange = (type) => {
        if(type == 'add'){
            setCurrentCount(currentCount + 1);
            
        }else{
            if(currentCount != 1){
                setCurrentCount(currentCount - 1);
            }
        }

    }


    const addInventory  = () => {
        let _sizes = [...sizes];
        _sizes.push({
			value : 0,
			name : '',
			color: ''
		});

        setSizes(_sizes);
    }


    const handleInventoryChange = (e, type, i) => {
        let _sizes = [...sizes];
        if(type == 'name'){
            _sizes[i].name = e.value
        }

        if(type == 'color'){
            _sizes[i].color = e.value
        }

        if(type == 'count'){
            _sizes[i].value = e;

            let _totalCount = 0;

            for (let j = 0; j < _sizes.length; j++) {
                if(_sizes[j].value){
                    _totalCount += _sizes[j].value;
                }
            }

            setTotalCount(_totalCount);
        }
        

        setSizes(_sizes);

    }


    const handleDeleteInventry = index => {
        let _sizes = [...sizes];
        _sizes.splice(index, 1);

        let _totalCount = 0;

            for (let j = 0; j < _sizes.length; j++) {
                if(_sizes[j].value){
                    _totalCount += _sizes[j].value;
                }
            }

            setTotalCount(_totalCount);
            setSizes(_sizes);

    }


    const handleCreateCategory = () => {

        if(category == '' || category == 'undefined' || category == null){

        }else{
            setCatLoader(true);
            let _subCategories = subCategories.filter(i => i != '').map(j => ({name: j}));

            let params = {
                name: category,
                campCode: props.userProfile.campCode,
                subCategory: JSON.stringify(_subCategories)
            }

            itemCategoryAddService(params).then(res => {
                setCatLoader(false)
                if(res.data.statusCode){
                    handleCloseModal();
                    getItemCategory();
                    notification.open({
                        description:
                            'Category Created Successfully',
                    }); 
                }
            })

        }

        
    }



    const handleShowModalProductDetail = (item) => {
        let _bookingList = [...bookingList];
        if(item?.programId?.length){
            let _fBookingList = _bookingList.filter(i => item.programId.find(j => j === i.programId));
            setFBookingList(_fBookingList);
        }else{
            let _fBookingList = _bookingList.filter(i => i.campId == item.campId);
            setFBookingList(_fBookingList);
        }
        setIsViewProducts(true);
        setActiveProduct(item);
    }

    
    

    const addAddonsToList = (totalQuantity, addonId,selectedSize,selectedColor,) => {
        if(totalQuantity> 0 ){
            const addonItem = activeProduct?.subItems.filter(item => item.id === addonId)[0]
            const _newAddon = {
                count:totalQuantity,
                color:selectedColor?.value,
                price:addonItem?.price,
                title:addonItem?.title,
                itemId: addonId,
                sizes:selectedSize?.value,
            }
            const _PreviousAddon = addons.filter(item => item.itemId !== addonId); 
            setAddons([..._PreviousAddon.filter(obj => Object.keys(obj).length !== 0 || obj.constructor !== Object), _newAddon])
        }
        
    }

    const calculateTotalProductPrice = () => {
        const totalPrice = addons.reduce((totalPrice, addon ) => Number(totalPrice) + (Number(addon.count) * Number(addon.price)), activeProduct? activeProduct?.price * currentCount : 0) 
        return totalPrice;
    }
    
  


    return (
        <div className="body_container pb-4">

            
            <div className="container">
                <div className='d-flex justify-content-between align-items-center mt-4'>
                    <div className='w-50'>
                        <div className="search_wrap m-0">
                            <i className="icon-search" />
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Search"
                                value={search}
                                onChange={e => (setSearch(e.target.value))} />
                        </div>
                    </div>
                    <div>

                        {
                            props?.userProfile?.UserType == 1 ? '' : <Fragment>
                                <button className='btn btn-primary mr-3' onClick={() => setCategoryModal(true)}>Add Category</button>
                                <button className='btn btn-primary' onClick={() => setAddProductModal(true)}>Add Product</button>
                     </Fragment>
                        }
                       
                        
                    </div>
                </div>


                {
                    props?.userProfile?.UserType == 1 ? <div className='product_cat_filters'>
                        <div onClick={() => setSelectedCamp('')} className={`product_camp_item ${selectedCamp == '' ? 'active' : ''}`}>
                            <span>All</span>
                        </div>
                    {
                        bookedCampsList.map((item, i) => (
                            <div
                                onClick={() => (setSelectedCamp(item._id))}
                                key={item._id}
                                className={`product_camp_item ${selectedCamp == item._id ? 'active' : ''}`}>
                                <span>{item.campName}</span>
                            </div>
                        ))
                    } 
                    </div> : ''
                }


                <div className='product_cat_filters'>
                    <div onClick={() => (setF_cat(''), setF_sub_cat(''))} className={`product_cat_item ${f_cat == '' ? 'active' : ''}`}>
                        <span>All</span>
                    </div>
                   {
                    catList.map((item, i) => (
                        <div
                            style={{zIndex: 999 - i}}
                            onClick={() => (setF_cat(item._id), item?.subCategory?.length == 0 ? setF_sub_cat('') : '')}
                            key={item._id}
                            className={`product_cat_item ${item?.subCategory?.length ? 'has_child_cat' : ''} ${f_cat == item._id ? 'active' : ''}`}>
                            {props?.userProfile?.UserType != 1 ? <i onClick={() => handleProductCatDelete(item._id)} className="icon-cross"  /> : ''}
                            
                            {item?.subCategory?.length ?  <span>{item.name}</span> :  <span>{item.name}</span>}
                            {
                                item?.subCategory?.length ? (
                                    <ul>{item?.subCategory.map(itm => <li key={itm._id} onClick={() => setF_sub_cat(itm._id)} className={`${itm._id == f_sub_cat ? 'active' : ''}`}>{itm.name}</li>)}</ul>
                                ) : ''
                            }
                        </div>
                    ))
                   } 
                </div>


                {pageLoader ? <div className="program_loader"><ProgramLoader /></div> : <div className='row'>
                    {
                        products.length ? products.map((item, i) => (
                            <div className='col-lg-3 col-md-6' key={item._id}>
                                <div className='product_card'>

                                    {
                                        item?.image?.length ? <Slider {...settings}>
                                                                {item?.image.map(img => (
                                                                        <img src={img} className="img-fluid pointer" onClick={() => handleShowModalProductDetail(item)} />
                                                                ))}
                                                            </Slider> : ''
                                    }

                            
                                    <div className='product_text'>
                                        <div className='pro_card_price'>
                                            <p>${item?.price?.toFixed(2)}</p>
                                            {item.categoryName ? <span className='p_cat_text'>{item.categoryName}</span> : ''}
                                            {(item?.type === 2 ) ? <span className='p_cat_text' style={{background:'green'}}>Sub-Product</span> : ''}
                                        </div>
                                        <h3 style={{color: item.textColor || '#000'}}>{item.title}</h3>
                                        
                                        
                                    </div>

                                    <div className='pb-2 pl-2 pr-2 text-center'>
                                        {
                                            props?.userProfile?.UserType !== 1 
                                            ? <button onClick={() => {setActiveProduct(item); handleProductEditModal(item)}} className="btn btn-primary btn-sm w-100">Edit Product</button> : 
                                            <Fragment>
                                                <button className={`btn ${item.inCart ? "btn-secondary" : "btn-primary"} btn-sm w-100` }onClick={() => handleShowModalProductDetail(item)}>{item.inCart ? 'Add Again' : 'Add to Cart'}</button>
                                            </Fragment>
                                        }
                                    </div>

                                    
                                </div>
                            </div>
                        )) : <div className="cm_empty m-auto"><Empty description="Data Not Found" /> </div>
                    }
                </div>}


                <div className='d-flex justify-content-center mt-5'>
                <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={count}
                        pageRangeDisplayed={4}
                        onPageChange={setPage}/>
                </div> 
                </div>
            </div>






            <Modal
                dialogClassName="h_auto"
                show={isViewProducts}
                animation={false}
                size="xl"
                centered>
                <Modal.Body>
                    <div className="p-2 py-5 product_moda input_err_pos">
                        <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>

                        <div className='row'>
                            <div className='col-lg-5'>
                            {
                                activeProduct?.image?.length ? <Slider {...settings}>
                                                        {activeProduct?.image.map(img => (
                                                                <img src={img} className="img-fluid" />
                                                        ))}
                                                    </Slider> : ''
                            }

        
                            </div>
                            <div className='col-lg-7'>
                                {/* <h3>{activeProduct.title}</h3> */}
                                <h3 style={{color: activeProduct.textColor || '#000'}}>{activeProduct.title}</h3>

                                

                                {props?.userProfile?.UserType == 1 ? <Fragment>


                                    <div className='d-flex justify-content-between align-items-center'>
                                        {getSizes(activeProduct.sizes)?.length ? <div  className="form-group w-100 mr-2">
                                            <label>Size</label>
                                            <Select
                                                className={`form-control ${selectedSizeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Size"
                                                value={selectedSize}
                                                onChange={e => (setSelectedSize(e), setSelectedSizeErr(''), setSelectedColor(''))}
                                                classNamePrefix="cm_select"
                                                options={getSizes(activeProduct.sizes)} />
                                             

                                            {selectedSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div> : ''}

                                        {
                                            getColors2(activeProduct.sizes, selectedSize.value)?.length ? <div className={`form-group w-100 ${getSizes(activeProduct.sizes)?.length ? 'ml-2' : ''}`}>
                                            <label>Color</label>
                                            <Select
                                                isDisabled={(getSizes(activeProduct.sizes).length && !selectedSize.value) ? true : false}
                                                className={`form-control ${selectedColorErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Color"
                                                value={selectedColor}
                                                onChange={e => (setSelectedColor(e), setSelectedColorErr(''))}
                                                classNamePrefix="cm_select"
                                                // options={getColors(activeProduct.sizes)?.length ? getColors(activeProduct.sizes) : []} />
                                                options={getColors2(activeProduct.sizes, selectedSize.value)} />

                                            {selectedColorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedColorErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        
                                        </div> : ''
                                        }                                       

                                        
                                    </div>


                                    <div className="quantity mb-4 mt-0">
                                        <button onClick={() => handleCountChange('remove')} className="inc"></button>
                                        <b>{currentCount}</b>
                                        <button onClick={() => handleCountChange('add')} className="dec"></button>
                                    </div>

                                </Fragment> : ''}


                                {leftItemErr ? <span className='leftItemErr'>{leftItemErr}</span> : ''}


                                <div className='form-group'>

                                    <Select
                                        placeholder="Select Program"
                                        components={{ NoOptionsMessage }}
                                        classNamePrefix="cm_select"
                                        onChange={e => (setBookingId(e), setBookingIdErr(''))}
                                        className={`form-control ${ bookingIdErr ? 'is-invalid' : '' }`}
                                        options={fBookingList.map(i => ({...i, label: `${i.programName} - ${i.bookingNumber} - ${i.camperName}`, value: i._id}))} />

                                        {bookingIdErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bookingIdErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                                


                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    

                                    <div className="product_price">
                                        <h2><b>Item Price: ${activeProduct?.price?.toFixed(2)}</b></h2>
                                    </div>
                                    <div className="product_price" >
                                        <h2><b>Total Price: ${calculateTotalProductPrice().toFixed(2)}</b></h2>
                                </div>

                                    
                                </div>
                                
                                <div className=''>
                                    {getHtml(activeProduct.desc)}
                                </div>
                                {
                                    (activeProduct && activeProduct?.subItems?.length > 0) &&  
                                    <>
                                    <h4>Add-ons</h4>
                                    <div className ="d-flex flex-column flex-sm-row flex-wrap align-content-start align-items-stretch">
                                        {       
                                        activeProduct?.subItems?.map(item => <Addon key ={item._id} item={item} updateAddons={addAddonsToList}/>)
                                        }
                                    </div>
                                    
                                    </>
                                }
                                    {
                                        props?.userProfile?.UserType !== 1 ? 
                                        <button onClick={() => handleProductEditModal(activeProduct)} className="btn btn-info btn-sm">Edit Product</button> : 
                                        <div className='mt-4 d-flex justify-content-end'>
                                            <button className="btn btn-primary btn-sm" onClick={() => handleAddToCartProduct(activeProduct)}>Add to Cart</button>
                                            {/* <button className="btn btn-secondary btn-sm ml-3" onClick={() => handleAddToCartProduct(activeProduct, 2)}>Check out</button> */}
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                       
                    </div>
                </Modal.Body>
            </Modal>






            <Modal
                dialogClassName="h_auto"
                show={addProductModal}
                animation={false}
                size="xl"
                centered>
                <Modal.Body>
                    <div className="p-2 product_modal input_err_pos">
                        <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
                        <h3>{activeProduct._id ? 'Edit' : 'Create'}  Product</h3>
                       
                        <form onSubmit={handleSubmit}>

                            <div className='row'>
                                <div className='col-lg-6 border-right'>
                                    <div className='form-group'>
                                        <label>Title<b>*</b></label>
                                        <input
                                            type='text'
                                            value={title}
                                            onChange={e => (setTitle(e.target.value), setTitleErr(''))}
                                            className={`form-control ${ titleErr ? 'is-invalid' : '' }`} />

                                        {titleErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{titleErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                        

                                    <div className='d-flex'>

                                        <div className='form-group w-100 mr-2'>
                                            <label>Price<b>*</b></label>
                                            <InputNumber
                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                onChange={e => (setPrice(e), setPriceErr(''))}
                                                value={price}
                                                min={0}
                                                precision={2}
                                                placeholder='$'
                                                step={1}
                                                className={`form-control ${ priceErr ? 'is-invalid' : '' }`} />
                                           
                                            {priceErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{priceErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>


        
                                        <div className='form-group w-100 ml-2'>
                                            <label>SKU Number</label>
                                            <input
                                                type='text'
                                                value={skuNumber}
                                                onChange={e => (setSkuNumber(e.target.value), setSkuNumberErr(''))}
                                                className={`form-control ${ skuNumberErr ? 'is-invalid' : '' }`} />

                                            {skuNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{skuNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>
                                       
                                    </div>


                                    <div className='form-group' style={{zIndex: '10001'}}>
                                        <label>Select Category</label>
                                        <Select
                                            className={`form-control`}
                                            placeholder="Select"
                                            value={selectedCat}
                                            onChange={e => setSelectedCat(e)}
                                            classNamePrefix="cm_select"
                                            options={catList.map(item => ({...item, label: item.name, value: item._id}))} />

                                 </div>


                                 <div className='form-group' style={{zIndex: '10000'}}>
                                        <label>Select Sub Category</label>
                                        <Select
                                            className={`form-control`}
                                            disabled={!selectedCat.subCategory}
                                            placeholder="Select"
                                            value={selectedSubCat}
                                            onChange={e => setSelectedSubCat(e)}
                                            classNamePrefix="cm_select"
                                            options={selectedCat?.subCategory?.map(item => ({...item, label: item.name, value: item._id}))} />

                                 </div>

                                    <div className='form-group' style={{zIndex: '9999'}}>
                                        <label>Text Color</label>
                                        <Select
                                            className={`form-control ${ textColorErr ? 'is-invalid' : '' }`}
                                            placeholder="Select"
                                            value={textColor}
                                            onChange={e => (setTextColor(e), setTextColorErr(''))}
                                            classNamePrefix="cm_select"
                                            options={colorsList.map(item => ({label: item, value: item}))} />

                                        {textColorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{textColorErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className='form-group' style={{zIndex: '999'}}>
                                        <label>Programs</label>
                                        <Select
                                            className="form-control with_auto_hight"
                                            placeholder="Select Program"
                                            isMulti
                                            value={programIds}
                                            onChange={handleSelectPrograms}
                                            classNamePrefix="cm_select"
                                            options={[
                                                {label: 'Select All', value: 'all'},
                                                ...allPrograms.map(item => ({label: item.programName, value: item._id}))
                                            ]} />

                                    </div>


                                    

                                   

                                 


                                    <div className="check_box_wrapper mt-3">
                                        <input
                                            type="checkbox" onChange={() => setRegistrationDisplay(registrationDisplay == 0 ? 1 : 0)} checked={registrationDisplay == 1} />
                                            <span className="check_box checkbox_dark">
                                                <span />
                                                Display Item During Registration
                                            </span>
                                    </div>

                                    <div className="check_box_wrapper mt-3">
                                        <input
                                             type="checkbox" onChange={() => setStoreDisplay(storeDisplay == 0 ? 1 : 0)} checked={storeDisplay == 1} />
                                            <span className="check_box checkbox_dark">
                                                <span />
                                                Display Item In Store
                                            </span>
                                    </div>

                                    
                                </div>
                                <div className='col-lg-6'>



                                <div className="text-center">
                                        <div className="icon_upload_wrapper upload_wrapper_sm">
                                            <input ref={mediaRef} type="file" onChange={onSelectFile} accept="image/x-png,image/jpeg" />
                                            <div><i className="icon-button" /><p>Upload Product Image</p></div>
                                        </div>
                                        <ReactCrop
                                            src={upImg}
                                            onImageLoaded={onLoad}
                                            crop={crop}
                                            onChange={c => setCrop(c)}
                                            onComplete={completedCrop} />

                                        {imagesErr ? <h6 className='err_msg mb-3'>{imagesErr}</h6> : ''}

                                        <div className="text-center mb-4">
                                            {upImg ? <span onClick={loader ? null : handleFileUpload} style={{paddingLeft: '50px', paddingRight: '50px'}} className={`btn btn-primary d-inline rounded-0 btn-block mt-3 btn_anim ${loader ? 'show_anim' : ''}`}>{loader ? 'Loading...' : 'Save'}</span> : ''}
                                        </div>
                                    
                                        <ul className="m-0 p-0 product_imgs">
                                            {images.map((item, i) => (
                                                    <li key={i}><img src={item} /> <i className="icon-cross" onClick={() => handleRemove(i)} /></li>
                                                ))}
                                        </ul>

                                       
                                    </div>


                                    <div className="form-group my_editer">
                                        <label>Description</label>
                                        <ReactQuill
                                            className=""
                                            theme="snow"
                                            value={desc}
                                            onChange={e => setDesc(e)} />
                                    </div>

                                 
                                           



                                    

                                    
                                </div>
                            </div>


                            <div className='form-group mt-5 pos_r_z'>
                                    <div className='d-flex align-content-center mb-4'>
                                        <label> {hasSize ? 'Sizes' : 'Total Count Available'}  <b>*</b></label>
                                    </div>

                                    <ul className='m-0 p-0'>
                                        {
                                            sizes.map((inventory, i) => (
                                                <li className='d-flex justify-content-between align-items-center mb-3' key={i}>

                                                    <Select
                                                        value={inventory.name ? {label: inventory.name, value: inventory.name} : ''}
                                                        onChange={e => handleInventoryChange(e, 'name', i)}
                                                        className={`form-control ${ false ? 'is-invalid' : '' }`}
                                                        placeholder="Select Size"
                                                        classNamePrefix="cm_select"
                                                        options={[{label: 'No Size', value: 'No Size'}, ...shortsAndTshirtSizes.map(item => ({label: item, value: item}))]} /> 

                                                    <Select
                                                        className={`form-control ml-3 ${ false ? 'is-invalid' : '' }`}
                                                        onChange={e => handleInventoryChange(e, 'color', i)}
                                                        value={inventory.color ? {label: inventory.color, value: inventory.color} : ''}
                                                        placeholder="Select Color"
                                                        classNamePrefix="cm_select"
                                                        options={[{label: 'No Color', value: 'No Color'}, ...colorsList.map(item => ({label: item, value: item}))]} /> 

                                                
                                                    <InputNumber
                                                        value={inventory.value ? inventory.value : ''}
                                                        onChange={e => handleInventoryChange(e, 'count', i)}
                                                        min={0}
                                                        precision={0}
                                                        placeholder='Total Count'
                                                        step={1}
                                                        className="form-control ml-3"  />

                                                    <i className="icon-delete pointer ml-3" onClick={() => handleDeleteInventry(i)} />
                                                </li>
                                            ))
                                        }
                                    </ul>

                                    <div className='mb-4'>
                                        <span className='btn btn-dark btn-sm' onClick={addInventory}>Add Inventory</span>
                                    </div>

                                    <h6><b>Total Count Available: {totalCount}</b></h6>                 
                        </div>
                        {/*Add Dropdown for all parent products and input for no of products */}
                        {  (activeProduct.type === 1) &&   
                            <div className='form-group mt-5 pos_r_z'>
                                <div className='d-flex align-content-center mb-4'>
                                        <label> Sub-Products</label>
                                </div >
                                <Select
                                className="form-control"
                                placeholder="Select Subproduct"
                                isMulti
                                value={subProductsIds}
                                onChange={handleSelectSubproducts}
                                classNamePrefix="cm_select"
                                options={[...subProducts.filter(prod => prod._id !== activeProduct._id).map(item => ({label: item.title, value: item._id}))]} />
                            </div>
                            
                            }

                            <div className='d-flex justify-content-center mt-3'>
                                {activeProduct._id ? <span onClick={handleDeleteProduct} className='btn btn-danger px-5 mr-3'>Delete</span> : ''}
                                <span onClick={handleCloseModal} className='btn btn-dark px-5'>Cancel</span>
                                <button type='submit'  disabled={btnLoader} className={`btn btn-primary rounded-0 ml-3 px-5 ${btnLoader ? 'btnLoader' : ''}`}>{activeProduct._id ? 'Update' : 'Create'}</button>
                            </div>


                        </form>
                       
                    </div>
                </Modal.Body>
            </Modal>













            <Modal
                dialogClassName="h_auto"
                show={categoryModal}
                animation={false}
                centered>
                <Modal.Body>
                    <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
                    <h5>Create Product Category</h5>

                    <div className='form-group mt-4'>
                        <label>Category Name</label>
                        <input
                            type='text'
                            value={category}
                            onChange={e => (setCategory(e.target.value), setCategoryErr(''))}
                            className={`form-control ${ categoryErr ? 'is-invalid' : '' }`} />

                        {categoryErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{categoryErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>

                    <label>Sub Categories</label>

                    {
                        subCategories?.map((item, i) => (
                            <div className='p_sub_cat'>
                                 <input
                                    type='text'
                                    value={item}
                                    onChange={e => handleCatChange(e, i)}
                                    className="form-control" />

                                <i className="icon-delete" onClick={() => handleDeleteCat(i)}/>
                            </div>
                        ))
                    }
                    
                    <div className='d-flex justify-content-center mt-3'>
                        <span className='btn btn-dark btn-sm' onClick={handleSubCatAdd}>Add Sub Categories</span>
                    </div>

                    


                    <div className='d-flex justify-content-center mt-5'>
                        <span onClick={handleCloseModal} className='btn btn-secondary px-5'>Cancel</span>
                        <span onClick={() => catLoader ? null : handleCreateCategory()} className={`btn btn-primary ml-3 px-5 ${catLoader ? 'btnLoader' : ''}`}>Create</span>
                    </div>

                    
                </Modal.Body>
            </Modal>



        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state?.user;
    let { preList } = state?.program;
    let { camp } = preList;
    
    return {
        userProfile,
        camp
    };
}
  
const mapDispatchToProps = dispatch => ({
    productCartCountAction: (params) => dispatch(productCartCountAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineStore);